<template>
  <ResourceInfoPopUp
    ref="resourcePopUp"
    :isVisible="showMentionInfo"
    :resourceData="mentionInfo"
    :showNotFoundError="showNoMentionFoundError"
  />
</template>
<script>
import ResourceInfoPopUp from "./ResourceInfoPopUp.vue";
import mentionsHelpers from "@/mixins/mentionsHelpers";
export default {
  components: {
    ResourceInfoPopUp,
  },
  mixins: [mentionsHelpers],
  mounted() {
    this.setMentionListeners();
  },
  destroyed() {
    this.removeMentionListeners();
  },
};
</script>

