<template>
  <ViewLayout
    :name="viewName"
    :noUser="false"
    :loading="false"
    :showTaskBar="showTaskBar"
    ref="viewWrapper"
    class="resource-list"
    :data-resource="selectedResourceTypeOpt"
  >
    <template #header>
      <MultiTasksDataChangeBar ref="multiTasksDateBar" />
    </template>

    <template #side-nav-view>
      <ResourcesCategoriesTree
        v-if="!loading && appDataLoaded"
        ref="sideCompWrapper"
      />
    </template>
    <template #main-view>
      <ResourcesListView
        :showTable="!loading && appDataLoaded"
        ref="tableWrapper"
      />
    </template>
  </ViewLayout>
</template>
<script>
import Vue from "vue";
import firebase from "@/services/firebase";
import ResourcesListView from "@/components/ResourcesListView/ResourcesList.vue";
import ResourcesCategoriesTree from "@/components/ResourcesCateTreeView/ResourcesCateTreeView.vue";
import ViewLayout from "@/layouts/ViewLayout.vue";
import MultiTasksDataChangeBar from "@/components/MultiTasksDataChangeBar";
import EventEmitter from "@/helpers/eventEmitter";
import { createUserActionsHistory } from "@/helpers/actionHistory";
import {
  expandAllParentsOfResource,
  getUserResources,
  initUserResources,
  processResourceData,
  processResources,
  resourcesCollection,
  setResourceIdInTree,
} from "@/helpers/resources";
import userDetailsMixin from "@/mixins/userDetailsMixin";
import handleUserActionHelperMixin from "@/mixins/handleUserActionHelperMixin";
import { mapActions, mapGetters, mapMutations } from "vuex";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";
import isPlainObject from "lodash/isPlainObject";
import DatabaseInterface from "@/services/DatabaseInterface";
import globalSearchBoxHelperMixin from "@/mixins/globalSearchBoxHelperMixin";
import mentionsHelpers from "@/mixins/mentionsHelpers";
import resourcesHelpersMixin from "@/mixins/resourcesHelpersMixin";
import { initUserNotes, notesCollection, processNotes } from "@/helpers/notes";
import {
  checkIfTaskIsParentRecurringTask,
  getFirstVirtualReccuringTask,
  processRawTaskList,
} from "@/helpers/tasks";
import { getAppColorThemeFromUserData } from "@/helpers/app";
import {
  SELECT_TASK_IN_ACTIVITY_GRID,
  TOGGLE_RESOURCE_FORM_EVENT,
} from "@/variables/events";
import { resourceTypeLabels } from "@/data/resources";
import { getCurrDate } from "@/helpers/dates";
import { getResourcesTipStatus } from "@/helpers/tips";
// import { resourceTypeLabels } from "@/data/resources";
export default {
  props: {
    returnViewInfo: {
      type: Object,
    },
  },
  components: {
    ViewLayout,
    ResourcesCategoriesTree,
    ResourcesListView,
    MultiTasksDataChangeBar,
  },
  data() {
    return {
      noUser: true,
      loading: true,
      showTaskBar: false,
      dataLoaded: false,
      loadTasks: true,
    };
  },
  mixins: [
    userDetailsMixin,
    handleUserActionHelperMixin,
    globalSearchBoxHelperMixin,
    resourcesHelpersMixin,
    mentionsHelpers,
  ],
  methods: {
    ...mapActions(["updateRootState", "toggleLoader", "storeCurrViewData"]),
    ...mapMutations(["setLoader"]),
    ...mapMutations("resourcesData", {
      setResourceData: "setData",
    }),
    ...mapMutations("task", {
      updateTasksState: "updateState",
    }),
    removeNotesListener() {
      if (this.notesListener) {
        this.notesListener();
      }
    },
    async patchResourceSelectedCategories(userId) {
      const userResInfo = getUserResources();

      const record = await resourcesCollection.getByKey(userId);
      if (record.exists) {
        const userData = record.data();

        if (!isPlainObject(userData?.selectedCategories)) {
          userResInfo.set({}, "selectedCategories", true);
        }

        if (isEmpty(userData?.categories["root"])) {
          await userResInfo.add(
            {
              root: {
                created: getCurrDate(),
                key: "root",
                modified: "",
                open: false,
                parentCatKey: "",
                refKey: "",
              },
            },
            "categories"
          );
        }
      }
    },

    handleNotesUpdates(doc) {
      const noteData = doc.data();
      const currRawNotes = this.rawNotes;
      const currNotes = this.notes;
      const currRawDirectories = this.directories;
      const notesUpdats = {};

      if (!isEqual(currRawDirectories, noteData.directories)) {
        notesUpdats.directories = noteData.directories;
      }

      if (!isEqual(currRawNotes, noteData.notes)) {
        if (isEmpty(currNotes) && !isEmpty(noteData.notes)) {
          notesUpdats.notes = processNotes(
            [...noteData.notes],
            currRawDirectories
          );
        }
        notesUpdats.rawNotes = noteData.notes;
      }

      this.updateNoteState({
        ...notesUpdats,
      });
    },
    async setUserNotesListener(userId) {
      const notesRes = await notesCollection.dbStore.doc(userId).get();

      if (notesRes.exists) {
        this.notesListener = notesCollection.dbStore
          .doc(userId)
          .onSnapshot(this.handleNotesUpdates);
      }
    },
    async init() {
      this.setLoader(true);
      this.loading = true;
      this.loadTasks = true;
      firebase.auth().onAuthStateChanged(async (user) => {
        if (!isEmpty(user)) {
          initUserResources(user.uid);
          await this.patchUserProjectResourceView(user.uid);
          const userAppData = await (
            await DatabaseInterface.get(user.uid).once("value")
          ).val();
          this.updateRootState({
            isResourceTipHidden: getResourcesTipStatus(),
            editFormSettings: userAppData.editFormSettings || {},
          });
          this.setUserDetails(user);
          this.noUser = false;
          this.showTaskBar = true;

          if (
            userAppData.isProjectsResourceModeEnabled === undefined ||
            userAppData.isProjectsResourceModeEnabled === false
          ) {
            this.updateRootState({
              currActiveView: "resources",
            });
          }

          createUserActionsHistory(user.uid);
          initUserNotes(user.uid);

          this.setUserActionListener();
          this.patchResourceSelectedCategories(user.uid);

          this.setUserNotesListener(user.uid);

          this.resourcesListener = resourcesCollection.dbStore
            .doc(user.uid)
            .onSnapshot(this.handleResourcesUpdates);
          DatabaseInterface.get(user.uid).on("value", this.handleTasksUpdates);
        } else {
          this.setLoader(false);
          document.location.href = "/";
        }
      });
    },
    removeTasksListener() {
      DatabaseInterface.get(this.userInfo.uid).off("value");
    },
    async getUserNotesAndSetInStore(userId) {
      const snapshot = await notesCollection.getByKey(userId);
      if (snapshot.exists) {
        const notesData = snapshot.data();
        this.updateNoteState({
          rawNotes: notesData.notes || [],
        });
      }
    },
    async handleTasksUpdates(snapshot, doNotParse) {
      const userTasksData = doNotParse ? snapshot : snapshot.val();
      const stateUpdates = {};
      const rootStateUpdates = {};

      const currResourceSettings = this.resourceSettings;
      const updatedResourceSettings = userTasksData.resourceSettings || {};
      const updatedTaskSettings = userTasksData.taskSettings || {};
      const newProjects = userTasksData.projects || {};
      const newCategories = userTasksData.categories || {};
      const newRawTasksMap = userTasksData.tasks || {};
      const currTasksProjects = this.tasksProjects;
      const currRawTasksMap = this.rawTasksMap;
      const currTasksCategories = this.tasksCategories;
      const currTaskSettings = this.taskSettings || {};
      // const currEditFormSettings = this.editFormSettings || {};
      // const updatedEditFormSettings = userTasksData.editFormSettings || {};
      stateUpdates.showClearedTasks = userTasksData?.showCompleted || false;
      if (!isEqual(currTasksProjects, newProjects)) {
        stateUpdates.projects = newProjects;
      }

      if (!isEqual(currRawTasksMap, newRawTasksMap)) {
        const newRawTasks = Object.values(newRawTasksMap);
        if (isEmpty(this.processedTasks)) {
          const { recurringTasksMap, tasks } = processRawTaskList(
            newRawTasks,
            newProjects,
            newCategories
          );
          stateUpdates.recurringTasksMap = Object.freeze({
            ...recurringTasksMap,
          });
          stateUpdates.tasks = Object.freeze([...tasks]);
        }
        stateUpdates.rawTasks = Object.freeze(newRawTasks);
        stateUpdates.rawTasksMap = Object.freeze(newRawTasksMap);
      }

      if (!isEqual(currTasksCategories, newCategories)) {
        stateUpdates.categories = newCategories;
      }

      if (!isEqual(currResourceSettings, updatedResourceSettings)) {
        rootStateUpdates.resourceSettings = updatedResourceSettings;
      }

      if (!isEqual(currTaskSettings, updatedTaskSettings)) {
        rootStateUpdates.taskSettings = updatedTaskSettings;
      }

      // if (!isEqual(currEditFormSettings, updatedEditFormSettings)) {
      //   rootStateUpdates.editFormSettings = updatedEditFormSettings;
      // }

      rootStateUpdates.selectedColorTheme =
        getAppColorThemeFromUserData(userTasksData);

      if (!isEmpty(stateUpdates)) {
        this.updateTasksState(stateUpdates);
      }

      this.updateRootState({
        ...rootStateUpdates,
      });

      if (this.loadTasks) {
        await Vue.nextTick();
        this.setLoader(false);
        this.loadTasks = false;
      }
    },
    handleResourcesUpdates(doc) {
      if (!this.loadTasks) {
        this.setLoader(false);
      }

      const data = doc.data();

      this.handleResourceDataUpdates(data, () => {
        this.loading = false;

        if (!this.dataLoaded) {
          this.dataLoaded = true;
        }
      });

      // const currResources = this.resources;
      // const currNewRawResources = data?.resources || {};
      // const currNewResourcesList = Object.values(currNewRawResources);
      // const currRawResources = this.rawResourcesMap;
      // const currResourceCategories = this.categories;

      // const selectedResourceTypeOpt = data?.selectedResourceType || "people";
      // let selectedCatIds = [];

      // const newCategoriesData = data?.categories || {};

      // // const currRaw
      // const updates = {
      //   // selectedCategories: data?.selectedCategories || [],
      //   collapsedGroupsList: data?.collapsedGroups || [],
      //   internalCollapsedGroups: data?.internalCollapsedGroups || [],
      //   selectedResourceTypeOpt,
      // };

      // if (!isEqual(currResourceCategories, newCategoriesData)) {
      //   updates.categories = newCategoriesData;
      // }
      // if (
      //   !isEmpty(data?.selectedCategories) &&
      //   isPlainObject(data?.selectedCategories)
      // ) {
      //   // if (isPlainObject(data?.selectedCategories)) {
      //   selectedCatIds =
      //     data?.selectedCategories[selectedResourceTypeOpt] || [];
      //   // }
      // }

      // updates.selectedCategories = selectedCatIds;
      // if (!isEqual(currNewRawResources, currRawResources)) {
      //   if (isEmpty(currResources) && !isEmpty(currNewResourcesList)) {
      //     updates.processedResources = processResources(
      //       currNewResourcesList,
      //       data.categories
      //     );
      //   }

      //   updates.rawResourcesMap = currNewRawResources;
      // }

      // this.updateResourcesState(updates);
      // if (
      //   !isEmpty(this.selectedCategories) &&
      //   this.selectedCategories.length === 1 &&
      //   currNewRawResources[this.selectedCategories[0]] &&
      //   selectedResourceTypeOpt ===
      //     currNewRawResources[this.selectedCategories[0]].type &&
      //   !this.isResourceFormOpen
      // ) {
      //   console.log("OPENMING RESOURCE ");
      //   const taskDataToUse = this.$route.params?.viewConfig?.taskData;

      //   const processedResourceData = processResourceData({
      //     ...currNewRawResources[this.selectedCategories[0]],
      //   });

      //   // if (curr[selectedIds[0]]) {
      //   //   this.openSelectedResourceInfo(selectedIds[0]);
      //   // } else if (this.categories[selectedIds[0]]) {
      //   //   this.openSelectedCategoryInfo(selectedIds[0]);
      //   // }
      //   // console.log('processedResourceData', processedResourceData)
      //   // console.log("selectedCategories ", JSON.stringify(this.selectedCategories), this.isResourceFormOpen)

      //   const formStateData = {
      //     data: { ...processedResourceData },
      //   };

      //   if (!isEmpty(taskDataToUse)) {
      //     formStateData.selectedTaskData = Object.freeze(taskDataToUse);
      //   }

      //   if (this.selectedResourceTypeOpt === processedResourceData.type) {
      //     this.openResourceInfoViewer({
      //       ...formStateData,
      //     });
      //   }
      // }
    },
    removeListeners() {
      if (this.resourcesListener) {
        // unsubscribe
        this.resourcesListener();
      }
    },
    clearTasksState() {
      this.updateTasksState({
        rawTasks: [],
        tasks: [],
        rawTasksMap: {},
        projects: {},
        categories: {},
        selectedTasks: [],
        additionalTaskFilters: {},
      });
    },
    async openResourceData() {
      await this.$nextTick();
      this.toggleLoader(false);
      const queryData = this.$route.query;
      const res = await this.openResourceItemDataOnNav({
        queryData,
        routeParams: { ...this.$route.params },
        returnViewInfo: { ...this.returnViewInfo },
      });

      if (!res.taskDataProcessed) {
        this.setTaskDataToSelect = {
          ...res.taskDataToUse,
        };
      }

      // let openForm = false,
      //   resourceIdToUse,
      //   resourceToOpen = {},
      //   selectTaskInList = false,
      //   taskDataToUse;
      // let taskStateUpdates = {};
      // let resourceStateUpdates = {};
      // if (!isEmpty(queryData)) {
      //   const resourceId = queryData.resourceId;
      //   if (resourceId && this.rawResourcesMap[resourceId]) {
      //     openForm = true;

      //     resourceIdToUse = resourceId;

      //     if (!isEmpty(linkedItemData)) {
      //       taskDataToUse = { ...linkedItemData };
      //     }
      //   }
      // } else if (!isEmpty(viewData)) {
      //   const { modeData, taskData } = viewData;

      //   if (!isEmpty(modeData?.additionalTaskFilters)) {
      //     taskStateUpdates.additionalTaskFilters =
      //       modeData?.additionalTaskFilters;
      //   }

      //   if (!isEmpty(modeData?.resourceFiltersList)) {
      //     resourceStateUpdates.filterResources = modeData?.resourceFiltersList;
      //   }

      //   if (!isEmpty(modeData) && !isEmpty(taskData)) {
      //     const { type, resourceIdToOpen: resourceId } = modeData;

      //     if (
      //       type === "form" &&
      //       resourceId &&
      //       this.rawResourcesMap[resourceId]
      //     ) {
      //       openForm = true;
      //       resourceIdToUse = resourceId;

      //       if (!isEmpty(taskData)) {
      //         taskDataToUse = { ...taskData };
      //       }
      //     } else if (type === "grid") {
      //       selectTaskInList = true;
      //       if (!isEmpty(taskData)) {
      //         taskDataToUse = { ...taskData };
      //       }
      //     }
      //   }
      // }

      // this.updateTasksState({
      //   ...taskStateUpdates,
      // });
      // this.setResourceData({
      //   ...resourceStateUpdates,
      // });

      // if (!isEmpty(storedReturnInfoData)) {
      //   this.storeCurrViewData(Object.freeze(storedReturnInfoData));
      // }
      // if (openForm && resourceIdToUse) {
      //   const resourceData = this.resources.find(
      //     (r) => r.key === resourceIdToUse
      //   );
      //   if (!isEmpty(resourceData)) {
      //     resourceToOpen = { ...resourceData };
      //   }

      //   this.openResourceInForm({
      //     resourceData: { ...resourceToOpen },
      //     linkedItemData: { ...taskDataToUse },
      //     returnInfoToPass: storedReturnInfoData,
      //   });
      // } else if (selectTaskInList && !isEmpty(taskDataToUse)) {
      //   const { key, date } = taskDataToUse;
      //   let taskIdToUse = key;

      //   if (!isEmpty(this.rawTasksMap[key])) {
      //     if (checkIfTaskIsParentRecurringTask(this.rawTasksMap[key])) {
      //       taskIdToUse = getFirstVirtualReccuringTask(
      //         this.processedTasks,
      //         key,
      //         date
      //       )?.key;
      //     }

      //     if (taskIdToUse) {
      //       EventEmitter.emit(
      //         SELECT_TASK_IN_ACTIVITY_GRID,
      //         taskIdToUse,
      //         true,
      //         true
      //       );
      //     }
      //   } else {
      //     this.setTaskDataToSelect = {
      //       ...taskDataToUse,
      //     };
      //   }
      // }

      // // if()

      // if (openForm || selectTaskInList) {
      //   this.$router.push({
      //     query: {},
      //   });
      // }
    },

    openResourceDataAfterTasksLoad() {
      if (!isEmpty(this.setTaskDataToSelect)) {
        const { key, date } = this.setTaskDataToSelect;
        let taskIdToUse = key;

        if (!isEmpty(this.rawTasksMap[key])) {
          if (checkIfTaskIsParentRecurringTask(this.rawTasksMap[key])) {
            taskIdToUse = getFirstVirtualReccuringTask(
              this.processedTasks,
              key,
              date
            )?.key;
          }

          if (taskIdToUse) {
            EventEmitter.emit(
              SELECT_TASK_IN_ACTIVITY_GRID,
              taskIdToUse,
              true,
              true
            );
          }
        }

        this.setTaskDataToSelect = undefined;
      }
      // let openForm = false,
      //   resourceIdToUse,
      //   selectTaskInList = false,
      //   resourceToOpen = {},
      //   taskDataToUse;
      // const viewData = this.$route.params?.viewConfig;

      // if (!isEmpty(viewData)) {
      //   const { modeData, taskData } = viewData;

      //   if (!isEmpty(modeData) && !isEmpty(taskData)) {
      //     const { type, resourceIdToOpen: resourceId } = modeData;
      //     if (
      //       type === "form" &&
      //       resourceId &&
      //       this.rawResourcesMap[resourceId]
      //     ) {
      //       openForm = true;
      //       resourceIdToUse = resourceId;

      //       console.debug("TASKKKKK", taskData);
      //       if (!isEmpty(taskData)) {
      //         taskDataToUse = { ...taskData };
      //       }
      //     } else if (type === "grid") {
      //       selectTaskInList = true;
      //       if (!isEmpty(taskData)) {
      //         taskDataToUse = { ...taskData };
      //       }
      //     }
      //   }
      // }

      // if (openForm && resourceIdToUse) {
      //   const resourceData = this.resources.find(
      //     (r) => r.key === resourceIdToUse
      //   );
      //   if (!isEmpty(resourceData)) {
      //     resourceToOpen = { ...resourceData };
      //   }

      //   this.openResourceInForm({
      //     resourceData: { ...resourceToOpen },
      //     linkedItemData: { ...taskDataToUse },
      //     returnInfoToPass: {},
      //   });
      // } else if (selectTaskInList && !isEmpty(taskDataToUse)) {
      //   const { key, date } = taskDataToUse;
      //   let taskIdToUse = key;

      //   if (checkIfTaskIsParentRecurringTask(this.rawTasksMap[key])) {
      //     taskIdToUse = getFirstVirtualReccuringTask(
      //       this.processedTasks,
      //       key,
      //       date
      //     )?.key;
      //   }

      //   console.debug("AJJSJJS", taskIdToUse);
      //   if (taskIdToUse) {
      //     EventEmitter.emit(SELECT_TASK_IN_ACTIVITY_GRID, taskIdToUse);
      //   }
      // }

      // if()

      // if (openForm) {
      //   this.$router.replace({
      //     name: this.$route.name,
      //   });
      // }
    },

    // async openResourceInForm({
    //   resourceData,
    //   linkedItemData,
    //   returnInfoToPass = {},
    // }) {
    //   EventEmitter.emit(TOGGLE_RESOURCE_FORM_EVENT, false);

    //   const resourceDataToOpen = {
    //     ...resourceData,
    //     linkedItemData: !isEmpty(linkedItemData)
    //       ? Object.freeze(linkedItemData)
    //       : {},
    //     openDescr: true,
    //   };
    //   await setResourceIdInTree(resourceData);
    //   expandAllParentsOfResource(resourceData);

    //   await Vue.nextTick();
    //   // setTimeout(() => {
    //   this.openResourceInfoViewer({
    //     data: resourceDataToOpen,
    //     selectedTaskData: !isEmpty(linkedItemData)
    //       ? Object.freeze(linkedItemData)
    //       : {},
    //     returnViewInfo: { ...returnInfoToPass },
    //   });
    //   // }, 0);
    // },
    ...mapActions("resourceInfo", [
      "openResourceInfoViewer",
      "closeResourceInfoViewer",
    ]),
    ...mapActions("resourcesData", ["updateResourcesState"]),
    ...mapActions("note", ["updateNoteState"]),
  },
  computed: {
    ...mapGetters(["resourceSettings", "taskSettings"]),
    ...mapGetters("task", {
      tasksProjects: "projects",
      tasksCategories: "categories",
      rawTasksMap: "rawTasksMap",
      processedTasks: "tasks",
    }),
    ...mapGetters("resourcesData", [
      "resources",
      "selectedResourceTypeOpt",
      "rawResourcesMap",
      "categories",
      "selectedCategories",
    ]),
    ...mapGetters("resourceInfo", {
      isResourceFormOpen: "isOpen",
      resourceData: "data",
    }),
    ...mapGetters("note", ["notes", "rawNotes", "directories"]),
    viewName() {
      // return !this.selectedResourceTypeOpt ||
      //   this.selectedResourceTypeOpt === "all"
      //   ? "Resources"
      //   : resourceTypeLabels[this.selectedResourceTypeOpt];

      let resourceLabelToUse =
        this.selectedResourceTypeOpt && this.selectedResourceTypeOpt !== "all"
          ? resourceTypeLabels[this.selectedResourceTypeOpt]
          : "";

      let labelToUse = "Context";

      if (resourceLabelToUse) {
        const adjustedLabel = (
          resourceLabelToUse !== "People"
            ? `${resourceLabelToUse}s`
            : resourceLabelToUse
        ).toLowerCase();
        labelToUse = `${adjustedLabel}`;
      }
      return labelToUse;
    },
    resourceClasses() {
      return {
        [this.selectedResourceTypeOpt]: true,
      };
    },
    appDataLoaded() {
      return this.dataLoaded && !this.loadTasks;
    },
  },
  watch: {
    dataLoaded(n) {
      if (n) {
        this.openResourceData();
      }
    },
    loadTasks: {
      handler(n, o) {
        if (o !== n && n !== undefined && !n) {
          this.openResourceDataAfterTasksLoad();
        }
      },
    },
    $route: {
      handler: function (n, o) {
        // put your code here
        if (
          n?.query?.resourceId !== o?.query?.resourceId &&
          n?.query?.resourceId
        ) {
          this.openResourceData();
        }
      },
      deep: true,
    },
  },
  created() {
    this.dataLoaded = false;
    this.loading = true;
    this.loadTasks = true;
    this.updateTasksState();
  },
  mounted() {
    this.init();
    EventEmitter.on("unsubscribe", this.removeListeners);
  },
  beforeDestroy() {
    this.clearTasksState();

    this.closeResourceInfoViewer();
    this.updateNoteState({
      rawNotes: [],
      directories: {},
      notes: [],
    });
    this.updateRootState({
      searchVal: "",
    });

    this.removeTasksListener();
    this.removeNotesListener();
    this.removeUserActionListener();
    this.removeListeners();
    EventEmitter.off("unsubscribe", this.removeListeners);
  },
};
</script>
<style scoped>
/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f9f9f9;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border-radius: 7px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #e1e1e1;
}

.width-fixed {
  width: 280px;
}

.el-fixed {
  position: fixed;
}

.mode-select-bar {
  background-color: #eeeeee;
  top: 36px;
  padding-right: 0px !important;
}

.calender-view {
  /* top: 120px;
  bottom: 8px; */
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.reset-calender-top {
  top: 80px;
  padding-top: 10px;
}

.v-btn--active >>> svg {
  fill: rgb(255, 152, 0) !important;
}

.side-nav {
  width: 100%;
  max-width: 280px;
  position: relative;
  height: 100%;
  background-color: #f9f9f9;
}

.side-nav .side-nav-content {
  width: 100%;
  max-width: 280px;
  position: fixed;
}

.sidebar-title {
  font-size: 1.3845rem;
  padding: 2px;
}
.main {
  width: 100%;
}

.directory-tree {
  top: 120px;
}

.v-btn--active >>> .v-icon {
  color: var(--primary-color) !important;
}
</style>

<style lang="scss">
// .resource-list {
//   // --project-primary: #e0edff;
//   // --project-secondary: #1c94a7;
//   // --people-primary: #ffd8d8;
//   // --people-secondary: #d51010;
//   // --place-primary: #ddffd8;
//   // --place-secondary: #17b300;
//   // --thing-primary: #efe2ff;
//   // --thing-secondary: #6700e6;

//   .datagrid-cell:not(:first-child) {
//     background-color: transparent;
//   }
//   .datagrid-row[role="row"].row-selected {
//     &:not(.row-editing) {
//       .datagrid-cell:not(.intend-cell) {
//         background: none !important;
//       }
//     }
//   }

//   div.resource-tasks-grid .datagrid-row[role="row"].row-selected {
//     &:not(.row-editing) {
//       .datagrid-cell.row-handler-cell {
//         background: var(--selection-color) !important;
//       }
//     }
//   }
//   .e-treeview .e-list-item.e-active:not(.e-editing) > .e-fullrow {
//     background-color: transparent !important;
//     border-width: 0px !important;
//   }

//   .e-treeview
//     .e-list-item.e-active:not(.e-editing)
//     > .e-text-content
//     > div.e-icons:before {
//     background-color: transparent !important;
//   }

//   .e-treeview
//     li.resource-sub-category
//     span.resource-sub-category
//     > .data-container {
//     padding-right: 24px !important;
//   }
//   &[data-resource="project"] {
//     .resources-table .datagrid-row[role="row"] {
//       &.row-selected {
//         background-color: var(--project-primary) !important;

//         &:not(.row-editing) {
//           .datagrid-cell.row-handler-cell {
//             background: var(--selection-color) !important;
//             & .v-icon svg {
//               color: var(--project-secondary) !important;
//             }
//           }
//         }
//       }

//       &:hover:not(.row-editing) {
//         .datagrid-cell:not(.intend-cell) {
//           border-top: 1px solid var(--project-secondary) !important;
//           border-bottom: 1px solid var(--project-secondary) !important;
//         }
//         .row-handler-cell {
//           border-left: 1px solid var(--project-secondary) !important;
//         }
//       }
//     }

//     .e-treeview .e-list-item.e-active > .e-text-content .e-list-text {
//       color: var(--project-secondary) !important;
//     }

//     .e-treeview
//       .e-list-item.e-active:not(.e-editing)
//       > .e-text-content
//       > div.e-icons:before {
//       color: var(--project-secondary) !important;
//       // background-color: var(--project-primary) !important;
//     }

//     .e-treeview li.resource-sub-category span.resource-sub-category::before {
//       content: "";
//       display: inline-block;
//       width: 10px;
//       height: 10px;
//       margin-right: 5px;
//       -moz-border-radius: 7.5px;
//       -webkit-border-radius: 7.5px;
//       border-radius: 7.5px;
//       background-color: var(--project-secondary) !important;
//     }

//     .descr-editor-wrapper .ql-editor {
//       background-color: var(--project-editor-color);
//     }

//     // .e-treeview
//     //   li.resource-sub-category
//     //   span.resource-sub-category
//     //   > .data-container {
//     //   padding-right: 24px !important;
//     // }

//     // div.resource-tasks-grid {
//     //   .unscheduled-btn,
//     //   .scheduled-btn {
//     //     .v-btn__content .v-icon svg {
//     //       color: var(--project-secondary) !important;
//     //     }
//     //   }

//     //   .task-order-number-text-wrapper {
//     //     background-color: var(--project-secondary) !important;
//     //   }
//     // }
//   }

//   &[data-resource="people"] {
//     .resources-table .datagrid-row[role="row"] {
//       &.row-selected {
//         background-color: var(--people-primary) !important;
//         &:not(.row-editing) {
//           .datagrid-cell.row-handler-cell {
//             background: var(--selection-color) !important;
//             & .v-icon svg {
//               color: var(--people-secondary) !important;
//             }
//           }
//         }
//       }

//       &:hover:not(.row-editing) {
//         .datagrid-cell:not(.intend-cell) {
//           border-top: 1px solid var(--people-secondary) !important;
//           border-bottom: 1px solid var(--people-secondary) !important;
//         }
//         .row-handler-cell {
//           border-left: 1px solid var(--people-secondary) !important;
//         }
//       }
//     }

//     .e-treeview .e-list-item.e-active > .e-text-content .e-list-text {
//       color: var(--people-secondary) !important;
//     }
//     // .e-treeview .e-list-item.e-active:not(.e-editing) > .e-fullrow {
//     //   // background-color: var(--people-primary) !important;
//     // }

//     .e-treeview
//       .e-list-item.e-active:not(.e-editing)
//       > .e-text-content
//       > div.e-icons:before {
//       color: var(--people-secondary) !important;
//       // background-color: var(--people-primary) !important;
//     }
//     .e-treeview li.resource-sub-category span.resource-sub-category::before {
//       content: "";
//       display: inline-block;
//       width: 10px;
//       height: 10px;
//       margin-right: 5px;
//       -moz-border-radius: 7.5px;
//       -webkit-border-radius: 7.5px;
//       border-radius: 7.5px;
//       background-color: var(--people-secondary) !important;
//     }

//     .descr-editor-wrapper .ql-editor {
//       background-color: var(--people-editor-color);
//     }

//     // div.resource-tasks-grid {
//     //   .unscheduled-btn,
//     //   .scheduled-btn {
//     //     .v-btn__content .v-icon svg {
//     //       color: var(--people-secondary) !important;
//     //     }
//     //   }

//     //   .task-order-number-text-wrapper {
//     //     background-color: var(--people-secondary) !important;
//     //   }
//     // }
//   }

//   &[data-resource="place"] {
//     .resources-table .datagrid-row[role="row"] {
//       &.row-selected {
//         background-color: var(--place-primary) !important;

//         &:not(.row-editing) {
//           .datagrid-cell.row-handler-cell {
//             background: var(--selection-color) !important;
//             & .v-icon svg {
//               color: var(--place-secondary) !important;
//             }
//           }
//         }
//       }

//       &:hover:not(.row-editing) {
//         .datagrid-cell:not(.intend-cell) {
//           border-top: 1px solid var(--place-secondary) !important;
//           border-bottom: 1px solid var(--place-secondary) !important;
//         }
//         .row-handler-cell {
//           border-left: 1px solid var(--place-secondary) !important;
//         }
//       }
//     }

//     .e-treeview .e-list-item.e-active > .e-text-content .e-list-text {
//       color: var(--place-secondary) !important;
//     }
//     // .e-treeview .e-list-item.e-active:not(.e-editing) > .e-fullrow {
//     //   // background-color: var(--place-primary) !important;
//     // }

//     .e-treeview
//       .e-list-item.e-active:not(.e-editing)
//       > .e-text-content
//       > div.e-icons:before {
//       color: var(--place-secondary) !important;
//       // background-color: var(--place-primary) !important;
//     }
//     .e-treeview li.resource-sub-category span.resource-sub-category::before {
//       content: "";
//       display: inline-block;
//       width: 10px;
//       height: 10px;
//       margin-right: 5px;
//       -moz-border-radius: 7.5px;
//       -webkit-border-radius: 7.5px;
//       border-radius: 7.5px;
//       background-color: var(--place-secondary) !important;
//     }

//     .descr-editor-wrapper .ql-editor {
//       background-color: var(--place-editor-color);
//     }
//     // div.resource-tasks-grid {
//     //   .unscheduled-btn,
//     //   .scheduled-btn {
//     //     .v-btn__content .v-icon svg {
//     //       color: var(--place-secondary) !important;
//     //     }
//     //   }

//     //   .task-order-number-text-wrapper {
//     //     background-color: var(--place-secondary) !important;
//     //   }
//     // }
//   }

//   &[data-resource="thing"] {
//     .resources-table .datagrid-row[role="row"] {
//       &.row-selected {
//         background-color: var(--thing-primary) !important;
//         &:not(.row-editing) {
//           .datagrid-cell.row-handler-cell {
//             background: var(--selection-color) !important;
//             & .v-icon svg {
//               color: var(--thing-secondary) !important;
//             }
//           }
//         }
//       }

//       &:hover:not(.row-editing) {
//         .datagrid-cell:not(.intend-cell) {
//           border-top: 1px solid var(--thing-secondary) !important;
//           border-bottom: 1px solid var(--thing-secondary) !important;
//         }
//         .row-handler-cell {
//           border-left: 1px solid var(--thing-secondary) !important;
//         }
//       }
//     }

//     .e-treeview .e-list-item.e-active > .e-text-content .e-list-text {
//       color: var(--thing-secondary) !important;
//     }
//     // .e-treeview .e-list-item.e-active:not(.e-editing) > .e-fullrow {
//     //   // background-color: var(--thing-primary) !important;
//     // }

//     .e-treeview
//       .e-list-item.e-active:not(.e-editing)
//       > .e-text-content
//       > div.e-icons:before {
//       color: var(--thing-secondary) !important;
//       // background-color: var(--thing-primary) !important;
//     }
//     .e-treeview li.resource-sub-category span.resource-sub-category::before {
//       content: "";
//       display: inline-block;
//       width: 10px;
//       height: 10px;
//       margin-right: 5px;
//       -moz-border-radius: 7.5px;
//       -webkit-border-radius: 7.5px;
//       border-radius: 7.5px;
//       background-color: var(--thing-secondary) !important;
//     }

//     .descr-editor-wrapper .ql-editor {
//       background-color: var(--thing-editor-color);
//     }

//     // div.resource-tasks-grid {
//     //   .unscheduled-btn,
//     //   .scheduled-btn {
//     //     .v-btn__content .v-icon svg {
//     //       color: var(--thing-secondary) !important;
//     //     }
//     //   }

//     //   .task-order-number-text-wrapper {
//     //     background-color: var(--thing-secondary) !important;
//     //   }
//     // }
//   }
// }

// .project-filter-bar {
//   .taskType-filters,
//   .type-filters {
//     .selected-filter {
//       background-color: var(--project-muted) !important;
//     }
//   }
//   .toggle-btn {
//     background-color: var(--project-muted) !important;
//   }
// }
// .people-filter-bar {
//   .taskType-filters,
//   .type-filters {
//     .selected-filter {
//       background-color: var(--people-muted) !important;
//     }
//   }

//   .toggle-btn {
//     background-color: var(--people-muted) !important;
//   }
// }
// .place-filter-bar {
//   .taskType-filters,
//   .type-filters {
//     .selected-filter {
//       background-color: var(--place-muted) !important;
//     }
//   }

//   .toggle-btn {
//     background-color: var(--place-muted) !important;
//   }
// }
// .thing-filter-bar {
//   .taskType-filters,
//   .type-filters {
//     .selected-filter {
//       background-color: var(--thing-muted) !important;
//     }
//   }

//   .toggle-btn {
//     background-color: var(--thing-muted) !important;
//   }
// }

// .project-filter-btn {
//   background-color: var(--project-primary) !important;
//   &.selected {
//     background-color: var(--project-muted) !important;
//     color: #fff !important;
//   }
// }
// .people-filter-btn {
//   background-color: var(--people-primary) !important;
//   &.selected {
//     background-color: var(--people-muted) !important;
//     color: #fff !important;
//   }
// }
// .place-filter-btn {
//   background-color: var(--place-primary) !important;
//   &.selected {
//     background-color: var(--place-muted) !important;
//     color: #fff !important;
//   }
// }
// .thing-filter-btn {
//   background-color: var(--thing-primary) !important;
//   &.selected {
//     background-color: var(--thing-muted) !important;
//     color: #fff !important;
//   }
// }

// .resource-filter-btn {
//   opacity: 1 !important;
// }
</style>
