<template>
  <v-app>
    <v-app-bar app color="primary" flat name="TaskBar">
      <p class="white--text text-h5 mb-0">Chaos To Intention</p>
    </v-app-bar>

    <v-main>
      <v-container>
        <v-card class="pa-10">

          <p><strong>Chaos To Intention Terms of Service</strong></p>

          <p>Welcome, and thank you for your interest in Chaos To Intention LLC ("<strong>Chaos To Intention</strong>,"
            "<strong>we</strong>," "<strong>our</strong>," or "<strong>us</strong>") and our website at
            ChaosToIntention.com,
            along with our related websites, networks, applications, and other services provided by us, including all
            successor versions or products (each as defined below, and collectively, the
            "<strong>Service</strong>").&nbsp;
            These Terms of Service are a legally binding contract between you and Chaos To Intention regarding your use
            of the
            Service.</p>

          <p><strong>PLEASE READ THE FOLLOWING TERMS CAREFULLY.</strong></p>

          <p><strong>BY CLICKING "I ACCEPT," OR BY DOWNLOADING, INSTALLING, OR OTHERWISE ACCESSING OR USING THE
              SERVICE</strong>, YOU AGREE THAT YOU HAVE READ AND UNDERSTOOD, AND, AS A CONDITION TO YOUR USE OF THE
            SERVICE,
            YOU AGREE TO BE BOUND BY, THE FOLLOWING TERMS AND CONDITIONS, INCLUDING CHAOS TO INTENTION'S PRIVACY POLICY
            (TOGETHER, THESE "<strong>TERMS</strong>").&nbsp; If you are not eligible, or do not agree to the Terms,
            then you
            do not have our permission to use the Service.&nbsp; YOUR USE OF THE SERVICE, AND CHAOS TO INTENTION'S
            PROVISION
            OF THE SERVICE TO YOU, CONSTITUTES AN AGREEMENT BY CHAOS TO INTENTION AND BY YOU TO BE BOUND BY THESE TERMS.
          </p>

          <p><strong>Arbitration NOTICE</strong>.&nbsp; Except for certain kinds of disputes described in Section 20
            (Dispute
            Resolution and Arbitration), you agree that disputes arising under these Terms will be resolved by binding,
            individual arbitration, and BY ACCEPTING THESE TERMS, YOU AND CHAOS TO INTENTION ARE EACH WAIVING THE RIGHT
            TO A
            TRIAL BY JURY OR TO PARTICIPATE IN ANY CLASS ACTION OR REPRESENTATIVE PROCEEDING.</p>

          <ol>
            <li><strong> Chaos To Intention Service Overview.</strong></li>
          </ol>

          <p>Chaos To Intention offers innovative productivity software allowing users to manage tasks, areas of
            activity,
            projects, people, places, things and other personally maintained information to stay on track doing what is
            most
            important all the time.</p>

          <ol start="2">
            <li><strong> Eligibility.</strong></li>
          </ol>

          <p>You must be at least 13 years old to use the Service.&nbsp; By agreeing to these Terms, you represent and
            warrant
            to us that: (a) you are at least 13 years old; (b) you have not previously been suspended or removed from
            the
            Service; and (c) your registration and your use of the Service is in compliance with any and all applicable
            laws
            and regulations.&nbsp; If you are an entity, organization, or company (an "<strong>Organization</strong>"),
            the
            individual accepting these Terms on your behalf represents and warrants that they have authority to bind you
            to
            these Terms and you agree to be bound by these Terms.</p>

          <ol start="3">
            <li><strong> Accounts and Registration.</strong></li>
          </ol>

          <p>To access the Service, you must register for an account.&nbsp; You may register using your Google account
            or you
            may create a separate account for the Service.&nbsp; When you register for an account, you may be required
            to
            provide us with some information about yourself, such as your name, email address, or other contact
            information.&nbsp; You agree that the information you provide to us is accurate and that you will keep it
            accurate
            and up-to-date at all times.&nbsp; When you register, you will be asked to provide a password.&nbsp; You are
            solely responsible for maintaining the confidentiality of your account and password, and you accept
            responsibility
            for all activities that occur under your account.&nbsp; If you believe that your account is no longer
            secure, then
            you must immediately notify us either by contacting customer service at&nbsp;<a
              href="https://chaostointention.com/contact">https://ChaosToIntention.com/contact</a> (depending on which
            Chaos
            To Intention Service product you are using).</p>

          <ol start="4">
            <li><strong> General Payment Terms.</strong></li>
          </ol>

          <p>Certain features of the Service may require you to pay fees.&nbsp; Before you pay any fees, you will have
            an
            opportunity to review and accept the fees that you will be charged.&nbsp; All fees are in the currencies
            provided
            on the Chaos To Intention website and, except as expressly provided by applicable law, are non-refundable,
            except
            required by law.</p>

          <p>4.1.&nbsp; <strong>Price</strong>.&nbsp; Chaos To Intention reserves the right to determine pricing for the
            Service.&nbsp; Chaos To Intention will make reasonable efforts to keep pricing information published on the
            website up to date which is available on our&nbsp;<a href="https://chaostointention.com/pricing">pricing
              page</a>&nbsp;(the "<strong>Pricing Page</strong>").&nbsp; All prices are shown on the Pricing Page.&nbsp;
            Any
            applicable sales taxes, levies, value-added taxes, or duties imposed by taxing authorities are calculated
            during
            the check-out based on your location .&nbsp; We encourage you to check our website periodically for current
            pricing information.&nbsp; Chaos To Intention may change the price of any feature of the Service; if this
            occurs,
            Chaos To Intention will provide you advance notice of the changes before they apply.&nbsp; Chaos To
            Intention, at
            its sole discretion, may make promotional offers with different features and different pricing to any of
            Chaos To
            Intention's customers.&nbsp; These promotional offers, unless made to you, will not apply to your offer or
            these
            Terms.</p>

          <p>4.2.&nbsp; <strong>Authorization</strong>.&nbsp; You authorize Chaos To Intention and its third-party
            payment
            processors to charge all sums for the orders that you make and any level of Service you select as described
            in
            these Terms or published by Chaos To Intention, including all applicable taxes, to the payment method
            specified in
            your account.&nbsp; If you pay any fees with a credit card, Chaos To Intention or its third-party payment
            processors may seek pre-authorization of your credit card account prior to your purchase to verify that the
            credit
            card is valid and has the necessary funds or credit available to cover your purchase.</p>

          <p>4.3.&nbsp; <strong>Subscription Service</strong>.&nbsp; The Service may include automatically recurring
            payments
            for periodic charges ("<strong>Subscription Service</strong>").&nbsp; If you activate a Subscription
            Service, you
            authorize Chaos To Intention or its third-party payment processors to periodically charge, on a
            going-forward
            basis and until cancellation of either the recurring payments or your account, all accrued sums on or before
            the
            payment due date for the accrued sums.&nbsp; The "<strong>Subscription Billing Date</strong>" is the date
            when you
            purchase your first subscription to the Service.&nbsp; For information on the "<strong>Subscription
              Fee</strong>",
            please see our&nbsp;<a href="https://chaostointention.com/pricing">Pricing Page</a>.&nbsp; Your account will
            be
            charged automatically on the Subscription Billing Date all applicable fees and taxes for the next
            subscription
            period (e.g., monthly billing periods will be billed on the same day each month).&nbsp; The subscription
            will
            continue unless and until you cancel your subscription or we terminate it.&nbsp; You must cancel your
            subscription
            before it renews in order to avoid billing of the next periodic Subscription Fee to your account.&nbsp;
            Chaos To
            Intention or its third-party payment processor will bill the periodic Subscription Fee to the payment method
            you
            provide to us during registration (or to a different payment method if you change your payment
            information).&nbsp;
            You may cancel the Subscription Service by following the instructions provided at the following links
            for&nbsp;<a href="https://chaostointention.com/help/articles/6039358868380">Chaos To Intention Pro</a>, or
            by contacting
            customer service at&nbsp;<a
              href="https://chaostointention.com/contact">https://ChaosToIntention.com/contact</a>.
          </p>

          <p>4.4.&nbsp; <strong>Delinquent Accounts</strong>.&nbsp; Chaos To Intention may suspend or terminate access
            to the
            Service, including fee-based portions of the Service, for any account for which any amount is due but
            unpaid.&nbsp; In addition to the amount due for the Service, a delinquent account will be charged with fees
            or
            charges that are incidental to any chargeback or collection of any the unpaid amount, including collection
            fees.
          </p>

          <ol start="5">
            <li><strong> Chaos To Intention Service Products</strong></li>
          </ol>

          <p>5.1.&nbsp; <strong>General</strong>.&nbsp; Users may sign up for an account for the various Chaos To
            Intention
            Service products described below via the Chaos To Intention website as applicable.&nbsp; These Terms apply
            to all
            products that are a part of the Service.</p>

          <p>5.2.&nbsp; <strong>Chaos To Intention Pro.&nbsp; </strong>You may choose to sign up for the free version of
            Chaos
            To Intention or the paid subscription version of Chaos To Intention ("<strong>Chaos To Intention
              Pro</strong>"),
            which automatically renews at the end of your billing period consistent with the terms of Section 4.3
            (Subscription Service).&nbsp; Chaos To Intention does not represent or guarantee when, if ever, Chaos To
            Intention
            Pro features will be available in the free version of Chaos To Intention.&nbsp; You can upgrade from the
            free
            version of Chaos To Intention to Chaos To Intention Pro at any time by following instructions on
            our&nbsp;Pricing
            Page.</p>

          <ol start="6">
            <li><strong> Beta Services.</strong></li>
          </ol>

          <p>6.1.&nbsp; <strong>Generally.&nbsp; </strong>From time to time, Chaos To Intention may provide you with the
            opportunity to try products or services which are not generally available to users ("<strong>Beta
              Services</strong>").&nbsp; This period of time will be referred to as the "<strong>Beta Services
              Period</strong>".&nbsp; Beta Services will be designated as beta, pilot, non-production, evaluation, or by
            another description with a similar meaning.&nbsp; You may accept or decline an invitation at your sole
            discretion.&nbsp; You may use the Beta Services solely for evaluation purposes. &nbsp;By using the Beta
            Services,
            you agree to: (a) cooperate with Chaos To Intention in evaluating the Beta Services; and (b) provide to
            Chaos To
            Intention any reasonably requested feedback, comments, and suggestions for improvements.</p>

          <p>6.2.&nbsp; <strong>Beta Services Usage Data</strong>.&nbsp; You acknowledge and agree that Chaos To
            Intention may
            collect anonymous data regarding usage and performance of the Service in connection with your use of the
            Beta
            Services ("<strong>Beta</strong>&nbsp;<strong>Services Usage Data</strong>").&nbsp; Beta Services Usage Data
            is
            and will remain the exclusive property of Chaos To Intention.&nbsp; Chaos To Intention may use and disclose
            the
            Beta Services Usage Data for its business purposes, including to monitor, improve, and market Chaos To
            Intention's
            products, provided that Chaos To Intention will not distribute or convey that data in a manner that could
            reasonably identify you as its source.</p>

          <p>6.3.&nbsp; <strong>Beta Services Disclaimer.&nbsp; </strong>You acknowledge and agree that the Beta
            Services may
            be inoperable or incomplete and may contain errors, design flaws, or other problems.&nbsp; Chaos To
            Intention is
            under no obligation to make any Beta Services generally available and Chaos To Intention may unilaterally
            discontinue any Beta Service at any time.&nbsp; Furthermore, you acknowledge and agree that you use all Beta
            Services at your own risk and that your use of the Beta Services may result in loss of User Content, data,
            or
            communications, project delays, or other unpredictable damage or loss.&nbsp; Notwithstanding anything to the
            contrary in Section 19 (Limitation of Liability), and to the fullest extent permitted by law, Chaos To
            Intention
            will have no liability or responsibility to you or any third party with respect to any matter whatsoever
            regarding
            the Beta Services, including the loss of any of your User Content or other data or communications resulting
            from
            your use of the Beta Services.&nbsp; <strong>ALL BETA SERVICES ARE PROVIDED "AS-IS" WITHOUT ANY WARRANTY OF
              ANY
              KIND.&nbsp; THE DISCLAIMERS IN SECTION 18 (DISCLAIMERS; NO WARRANTIES) APPLY TO ALL BETA
              SERVICES.</strong></p>

          <ol start="7">
            <li><strong> Licenses</strong></li>
          </ol>

          <p>7.1.&nbsp; <strong>Limited License.&nbsp; </strong>Subject to your complete and ongoing compliance with
            these
            Terms, Chaos To Intention grants you, solely for your personal use or for your employer's or Organization's
            internal business operations, as applicable, a limited, non-exclusive, non-transferable, non-sublicensable,
            revocable license to access and use the Service to which you have subscribed, including the right for you to
            install and use a reasonable number of object code copies of any mobile application associated with the
            Service
            obtained from a legitimate marketplace on a mobile device that, as applicable, either you own or control, or
            your
            employer owns or controls.</p>

          <p>7.2.&nbsp; <strong>License Restrictions.&nbsp; </strong>Except and solely to the extent such a restriction
            is
            impermissible under applicable law or expressly authorized by us, you may not: (a) reproduce, distribute,
            publicly
            display, or publicly perform the Service; (b) make modifications to the Service; or (c) interfere with or
            circumvent any feature of the Service, including any security or access control mechanism.&nbsp; If you are
            prohibited under applicable law from using the Service, you may not use it.</p>

          <p>7.3.&nbsp; <strong>Feedback.&nbsp; </strong>If you choose to provide input and suggestions regarding
            problems
            with or proposed modifications or improvements to the Service (excluding Beta Services)
            ("<strong>Feedback</strong>"), then you hereby grant Chaos To Intention an unrestricted, perpetual,
            irrevocable,
            non-exclusive, fully-paid, royalty-free right to exploit the Feedback in any manner and for any purpose,
            including
            to improve the Service and create other products and services.</p>

          <ol start="8">
            <li><strong> Ownership; Proprietary Rights.</strong></li>
          </ol>

          <p>The Service is owned and operated by Chaos To Intention.&nbsp; The visual interfaces, graphics, design,
            compilation, information, data, computer code (including source code or object code), products, software,
            services, promotional content (including Chaos To Intention's blog content) and all other elements of the
            Service
            ("<strong>Materials</strong>") provided by Chaos To Intention are protected by intellectual property and
            other
            laws.&nbsp; All Materials included in the Service are the property of Chaos To Intention or its third-party
            licensors.&nbsp; Except as expressly authorized by Chaos To Intention, you may not make use of the
            Materials.&nbsp; Chaos To Intention reserves all rights to the Materials not granted expressly in these
            Terms.</p>

          <ol start="9">
            <li><strong> Third Party Terms</strong></li>
          </ol>

          <p>9.1.&nbsp; <strong>Third-Party Services and Linked Websites.&nbsp; </strong>Chaos To Intention may provide
            tools
            through the Service that enable you to export information, including User Content, to third-party services,
            including through features that allow you to link your account on Chaos To Intention with an account on the
            third-party service.&nbsp; By using one of these tools, you agree that Chaos To Intention may transfer that
            information to the applicable third-party service.&nbsp; Third-party services are not under Chaos To
            Intention's
            control, and, to the fullest extent permitted by law, Chaos To Intention is not responsible for any
            third-party
            service's use of your exported information.&nbsp; The Service may also contain links to third party
            websites.&nbsp; Linked websites are not under Chaos To Intention's control, and Chaos To Intention is not
            responsible for their content.</p>

          <p>9.2.&nbsp; <strong>Third Party Software.&nbsp; </strong>The Service may include or incorporate third party
            software components, integrations, or extensions that are generally available free of charge under licenses
            granting recipients broad rights to copy, modify, and distribute those components ("<strong>Third-Party
              Components</strong>").&nbsp; Although the Service is provided to you subject to these Terms, nothing in
            these
            Terms prevents, restricts, or is intended to prevent or restrict you from obtaining Third Party Components
            under
            the applicable third-party licenses or to limit your use of Third-Party Components under those third-party
            licenses.</p>

          <ol start="10">
            <li><strong> User Content</strong></li>
          </ol>

          <p>10.1.&nbsp; <strong>User Content Generally.&nbsp; </strong>Certain features of the Service may permit users
            to
            upload content to the Service and send content through the Service, including messages, reviews, photos,
            audio,
            video, images, folders, data, text, graphics, and other types of information, material, and works
            ("<strong>User
              Content</strong>") and to publish User Content on the Service.&nbsp; As between you and Chaos To
            Intention, you
            retain any copyright and other proprietary rights that you may hold in the User Content that you post to the
            Service.&nbsp; However, you acknowledge and agree that if you are an employee of an Organization, the
            applicable
            Organization may own the rights to that User Content.</p>

          <p>10.2.&nbsp; <strong>User Content License Grant to Chaos To Intention.&nbsp; </strong>By providing User
            Content to
            or via the Service, your content is still yours but you grant Chaos To Intention a worldwide, non-exclusive,
            irrevocable, royalty-free, fully paid right and license (with the right to sublicense) to use, host, store,
            translate, transfer, display, perform, reproduce, modify, display, distribute your User Content, in whole or
            in
            part, and make derivative works of all such User Content and your name, voice, and/or likeness as contained
            in
            your User Content, in whole or in part, and in any form, media, or technology, whether now known or
            hereafter
            developed, for use only in connection with the Service so we can provide you features like sharing and
            collaboration with authorized user.</p>

          <p>10.3.&nbsp; <strong>User Content Representations and Warranties.&nbsp; </strong>Chaos To Intention
            disclaims any
            and all liability in connection with User Content.&nbsp; You are solely responsible for your User Content
            and the
            consequences of providing User Content via the Service.&nbsp; By providing User Content via the Service, you
            affirm, represent, and warrant that:</p>

          <ol>
            <li>you are (or your Organization is) the creator and owner of the User Content, or have the necessary
              licenses,
              rights, consents, and permissions to authorize Chaos To Intention and users of the Service to use and
              distribute
              your User Content as necessary to exercise the licenses granted by you in this Section, in the manner
              contemplated by Chaos To Intention, the Service, and these Terms;</li>
          </ol>

          <ol>
            <li>your User Content, and the use of your User Content as contemplated by these Terms, does not and will
              not: (i)
              infringe, violate, or misappropriate any third party right, including any copyright, trademark, patent,
              trade
              secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary
              right;
              (ii) breach any contractual obligation that you owe to a third party, including any Organization; (iii)
              slander,
              defame, libel, or invade the right of privacy, publicity or other property rights of any other person; or
              (iv)
              cause Chaos To Intention to violate any law or regulation; and</li>
          </ol>

          <ol>
            <li>your User Content could not be deemed by a reasonable person to be objectionable, profane, indecent,
              pornographic, harassing, threatening, embarrassing, hateful, or otherwise inappropriate.</li>
          </ol>

          <p>10.4.&nbsp; <strong>User Content Disclaimer.&nbsp; </strong>We are under no obligation to edit or control
            User
            Content that you or other users post or publish, and will not be in any way responsible or liable for User
            Content.&nbsp; Chaos To Intention may, however, at any time and without prior notice, screen, remove, edit,
            or
            block any User Content that in our sole judgment violates these Terms or is otherwise objectionable.&nbsp;
            You
            understand that when you use the Service, you may be exposed to User Content from a variety of sources and
            acknowledge that User Content may be inaccurate, offensive, indecent, or objectionable.&nbsp; You agree to
            waive,
            and do waive, any legal or equitable right or remedy you have or may have against Chaos To Intention with
            respect
            to User Content.&nbsp; If notified by a user or content owner that User Content allegedly does not conform
            to
            these Terms, we may investigate the allegation and determine in our sole discretion whether to remove the
            User
            Content, which we reserve the right to do at any time and without notice.&nbsp; For clarity, Chaos To
            Intention
            does not permit copyright-infringing activities on the Service.</p>

          <p>10.5.&nbsp; <strong>Monitoring Content.&nbsp; </strong>Chaos To Intention does not control and does not
            have any
            obligation to monitor: (a) User Content; (b) any content made available by third parties; or (c) the use of
            the
            Service by its users.&nbsp; You acknowledge and agree that Chaos To Intention reserves the right to, and may
            from
            time to time, monitor any and all information transmitted or received through the Service for operational
            and
            other purposes.&nbsp; If at any time Chaos To Intention chooses to monitor the content, Chaos To Intention
            still
            assumes no responsibility or liability for content or any loss or damage incurred as a result of the use of
            content.&nbsp; During monitoring, information may be examined, recorded, copied, and used in accordance with
            our
            Privacy Policy.</p>

          <ol start="11">
            <li><strong> Communications</strong></li>
          </ol>

          <p>11.1.&nbsp; <strong>Push Notifications.&nbsp; </strong>You may receive notifications on your computer
            hardware
            devices (e.g., laptop, desktop, or tablet).&nbsp; You can turn notifications on or off by visiting your
            device's
            "settings" page.</p>

          <p>11.2.&nbsp; <strong>Email.&nbsp; </strong>We may send you emails concerning our products and services, as
            well as
            those of third parties.&nbsp; You may opt out of promotional emails by following the unsubscribe
            instructions in
            the promotional email itself or by visiting&nbsp;ChaosToIntention.com/unsubscribe&nbsp; and logging into
            your
            account.</p>

          <ol start="12">
            <li><strong> Prohibited Conduct.</strong></li>
          </ol>

          <p>BY USING THE SERVICE YOU AGREE NOT TO:</p>

          <p>(a) use the Service for any illegal purpose or in violation of any local, state, national, or international
            law;
          </p>

          <p>(b) harass, threaten, demean, embarrass, or otherwise harm any other user of the Service;</p>

          <p>(c) violate, or encourage others to violate, any right of a third party, including by infringing or
            misappropriating any third-party intellectual property right or using or disclosing the confidential
            information
            of any third party without permission;</p>

          <p>(d) interfere with security-related features of the Service, including by: (i) disabling or circumventing
            features that prevent or limit use or copying of any content, including controls or restrictions implemented
            by
            Administrators; or (ii) reverse engineering or otherwise attempting to discover the source code of any
            portion of
            the Service except to the extent that the activity is expressly permitted by applicable law;</p>

          <p>(e) interfere with the operation of the Service or any user's enjoyment of the Service, including by: (i)
            uploading or otherwise disseminating any virus, adware, spyware, worm, or other malicious code; (ii) making
            any
            unsolicited offer or advertisement to another user of the Service; (iii) collecting personal information
            about
            another user or third party without consent; or (iv) interfering with or disrupting any network, equipment,
            or
            server connected to or used to provide the Service;</p>

          <p>(f) sell or otherwise transfer the access granted under these Terms or any Materials (as defined in Section
            9) or
            any right or ability to view, access, or use any Materials; or</p>

          <p>(g) attempt to do any of the acts described in this Section or assist or permit any person in engaging in
            any of
            the acts described in this Section.</p>

          <ol start="13">
            <li><strong> Digital Millennium Copyright Act</strong></li>
          </ol>

          <p>13.1.&nbsp; <strong>DMCA Notification.&nbsp; </strong>We comply with the provisions of the Digital
            Millennium
            Copyright Act applicable to Internet service providers (17 U.S.C.&nbsp; &sect;512, as amended).&nbsp; If you
            have
            an intellectual property rights-related complaint about material posted on the Service, you may contact our
            Designated Agent at the following address:</p>

          <p>Chaos To Intention LLC</p>
          <p>ATTN: Legal Department (Copyright Notification)</p>
          <p>176 Ridge Trail, Chapel HIll, NC 27516</p>
          <p>Phone: +1(919)802-6804</p>
          <p>Email: admin@ChaosToIntention.com</p>

          <p>Any notice alleging that materials hosted by or distributed through the Service infringe intellectual
            property
            rights must include the following information:</p>
          <ul>
            <li>an electronic or physical signature of the person authorized to act on behalf of the owner of the
              copyright or
              other right being infringed;</li>
            <li>a description of the copyrighted work or other intellectual property that you claim has been infringed;
            </li>
            <li>a description of the material that you claim is infringing and where it is located on the Service;</li>
            <li>your address, telephone number, and email address;</li>
            <li>a statement by you that you have a good faith belief that the use of the materials on the Service of
              which you
              are complaining is not authorized by the copyright owner, its agent, or the law; and</li>
            <li>a statement by you that the above information in your notice is accurate and that, under penalty of
              perjury,
              you are the copyright or intellectual property owner or authorized to act on the copyright or intellectual
              property owner's behalf.</li>
          </ul>

          <p>13.2.&nbsp; <strong>Repeat Infringers.&nbsp; </strong>Chaos To Intention will promptly terminate the
            accounts of
            users that are determined by Chaos To Intention to be repeat infringers.</p>

          <ol start="14">
            <li><strong> Modification of these Terms.</strong></li>
          </ol>

          <p>We reserve the right to change these Terms on a going-forward basis at any time.&nbsp; Please check these
            Terms
            periodically for changes.&nbsp; If a change to these Terms materially modifies your rights or obligations,
            we may
            require that you accept the modified Terms in order to continue to use the Service.&nbsp; Material
            modifications
            are effective upon your acceptance of the modified Terms.&nbsp; Immaterial modifications are effective upon
            publication.&nbsp; Except as expressly permitted in this Section, these Terms may be amended only by a
            written
            agreement signed by authorized representatives of the parties to these Terms.&nbsp; Disputes arising under
            these
            Terms will be resolved in accordance with the version of these Terms that was in effect at the time the
            dispute
            arose.</p>

          <ol start="15">
            <li><strong> Term, Termination and Modification of the Service</strong></li>
          </ol>

          <p>15.1.&nbsp; <strong>Term.&nbsp; </strong>These Terms are effective beginning when you accept the Terms or
            first
            download, install, access, or use the Service, and ending when terminated as described in Section 15.2
            (Termination).</p>

          <p>15.2.&nbsp; <strong>Termination.&nbsp; </strong>If you violate any provision of these Terms, your
            authorization
            to access the Service and these Terms automatically terminate.&nbsp; In addition, Chaos To Intention may, at
            its
            sole discretion, terminate these Terms or your account on the Service, or suspend or terminate your access
            to the
            Service, at any time for any reason or no reason, with or without notice.&nbsp; You may terminate your
            account and
            these Terms at any time by contacting customer service at&nbsp;<a
              href="https://chaostointention.com/contact">https://ChaosToIntention.com/contact</a>.&nbsp; If you lose
            access
            to an account or otherwise request information about you account, we reserve the right to request from you
            any
            verification we deem necessary before restoring access to or providing information about your account.&nbsp;
            Chaos
            To Intention is under no obligation to maintain or provide any User Content after termination.</p>

          <p>15.3.&nbsp; <strong>Effect of Termination.&nbsp; </strong>Upon termination of these Terms: (a) your license
            rights will terminate and you must immediately cease all use of the Service; (b) you will no longer be
            authorized
            to access your account or the Service; (c) you must pay Chaos To Intention all payment obligations that
            accrued
            prior to termination (all prepaid fees for the Service are non-refundable and non-cancellable unless
            otherwise
            stated on the&nbsp;<a href="https://chaostointention.com/help/articles/how-can-i-get-a-refund">help
              page</a>); and
            (d) the following Sections: 6.2 (Beta Services Usage Data), 6.3 (Beta Services Disclaimer), 7.3 (Feedback),
            8
            (Ownership; Proprietary Rights), 10.2 (User Content License Grant to Chaos To Intention), 10.3 (User Content
            License Grant to Other Users), 15.3 (Effect of Termination), 16 (Modification of the Service), 17
            (Indemnity), 18
            (Disclaimers; No Warranties), 19 (Limitation of Liability), 20 (Dispute Resolution and Arbitration) and 21
            (Miscellaneous) will survive.</p>

          <ol start="16">
            <li><strong> Modification of the Service.</strong></li>
          </ol>

          <p>Chaos To Intention reserves the right to modify or discontinue the Service at any time (including by
            limiting or
            discontinuing certain features of the Service), temporarily or permanently, without notice to you.&nbsp;
            Chaos To
            Intention will have no liability for any change to the Service or any suspension or termination of your
            access to
            or use of the Service.</p>

          <ol start="17">
            <li><strong> Indemnity.</strong></li>
          </ol>

          <p>To the fullest extent permitted by law, you are responsible for your use of the Service, and you will
            defend and
            indemnify Chaos To Intention and its officers, directors, employees, consultants, affiliates, subsidiaries
            and
            agents (together, the "<strong>Chaos To Intention Entities</strong>") from and against every claim brought
            by a
            third party, and any related liability, damage, loss, and expense, including reasonable attorneys' fees and
            costs,
            arising out of or connected with: (a) your unauthorized use of, or misuse of, the Service; (b) your
            violation of
            any portion of these Terms, any representation, warranty, or agreement referenced in these Terms, or any
            applicable law or regulation; (c) your violation of any third party right, including any intellectual
            property
            right or publicity, confidentiality, other property, or privacy right; (d) the nature or content of data
            processed
            by the Service; (e) User Content that violates federal, state, or local employment discrimination or
            harassment
            laws; (f) your use of the Service in violation of your obligations under any agreement between you and a
            third
            party, including, but not limited to, the maintenance and protection of third-party confidential information
            and
            trade secrets; or (g) any dispute or issue between you and any third party.&nbsp; We reserve the right, at
            our own
            expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by
            you
            (without limiting your indemnification obligations with respect to that matter), and in that case, you agree
            to
            cooperate with our defense of those claims.</p>

          <ol start="18">
            <li><strong> Disclaimers; No Warranties</strong></li>
          </ol>

          <p>THE SERVICE AND ALL MATERIALS AND CONTENT AVAILABLE THROUGH THE SERVICE ARE PROVIDED "AS IS" AND ON AN "AS
            AVAILABLE" BASIS.&nbsp; CHAOS TO INTENTION DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
            RELATING TO THE SERVICE AND ALL MATERIALS AND CONTENT AVAILABLE THROUGH THE SERVICE, INCLUDING: (A) ANY
            IMPLIED
            WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, OR NON-INFRINGEMENT;
            AND
            (B) ANY WARRANTY ARISING OUT OF COURSE OF DEALING, USAGE, OR TRADE.&nbsp; CHAOS TO INTENTION DOES NOT
            WARRANT THAT
            THE SERVICE OR ANY PORTION OF THE SERVICE, OR ANY MATERIALS OR CONTENT OFFERED THROUGH OR PROCESSED BY THE
            SERVICE, WILL BE UNINTERRUPTED, SECURE, OR FREE OF ERRORS, VIRUSES, OR OTHER HARMFUL COMPONENTS, AND CHAOS
            TO
            INTENTION DOES NOT WARRANT THAT ANY OF THOSE ISSUES WILL BE CORRECTED.&nbsp; NO ADVICE OR INFORMATION,
            WHETHER
            ORAL OR WRITTEN, OBTAINED BY YOU FROM THE SERVICE OR CHAOS TO INTENTION ENTITIES OR ANY MATERIALS OR CONTENT
            AVAILABLE THROUGH THE SERVICE WILL CREATE ANY WARRANTY REGARDING ANY OF THE CHAOS TO INTENTION ENTITIES OR
            THE
            SERVICE THAT IS NOT EXPRESSLY STATED IN THESE TERMS.&nbsp; WITHOUT LIMITING ANY DISCLAIMER IN THESE TERMS,
            WE ARE
            NOT RESPONSIBLE FOR ANY DAMAGE THAT MAY RESULT FROM (A) THE SERVICE; (B) THE ACTIONS OR INACTIONS OF THE
            TEAMS
            AUTHORIZED USERS THAT YOU INVITE TO YOUR WORKSPACE; (C) YOUR ABILITY OR INABILTITY TO ACCESS, EXPORT,
            TRANSFER,
            RETRIEVE, OR REMOVE ANY USER CONTENT FROM ANY WORKSPACE; (C) THE PROCESSING OR USE OF YOUR USER CONTENT BY
            AN
            ORGANIZATION; AND (D) YOUR DEALING WITH ANY OTHER SERVICE USER, ADMINISTRATOR, OR ORGANIZATION.&nbsp; YOU
            UNDERSTAND AND AGREE THAT YOU USE ANY PORTION OF THE SERVICE AT YOUR OWN DISCRETION AND RISK, AND THAT WE
            ARE NOT
            RESPONSIBLE FOR ANY DAMAGE TO YOUR PROPERTY (INCLUDING YOUR COMPUTER SYSTEM OR MOBILE DEVICE USED IN
            CONNECTION
            WITH THE SERVICE) OR ANY LOSS, USE, OR DISCLOSURE OF DATA, INCLUDING USER CONTENT.&nbsp; THE LIMITATIONS,
            EXCLUSIONS, AND DISCLAIMERS IN THIS SECTION APPLY TO THE FULLEST EXTENT PERMITTED BY LAW. &nbsp;Chaos To
            Intention
            does not disclaim any warranty or other right that Chaos To Intention is prohibited from disclaiming under
            applicable law.</p>

          <ol start="19">
            <li><strong> Limitation of Liability</strong></li>
          </ol>

          <p>TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL THE CHAOS TO INTENTION ENTITIES BE LIABLE TO YOU
            FOR ANY
            INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES (INCLUDING DAMAGES FOR LOSS OF PROFITS,
            GOODWILL,
            OR ANY OTHER INTANGIBLE LOSS) ARISING OUT OF OR RELATING TO YOUR ACCESS TO OR USE OF, OR YOUR INABILITY TO
            ACCESS
            OR USE, THE SERVICE OR ANY MATERIALS OR CONTENT ON OR AVAILABLE THROUGH THE SERVICE, WHETHER BASED ON
            WARRANTY,
            CONTRACT, TORT (INCLUDING NEGLIGENCE), STATUTE, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT ANY CHAOS TO
            INTENTION ENTITY HAS BEEN INFORMED OF THE POSSIBILITY OF DAMAGE.&nbsp; EXCEPT AS PROVIDED IN SECTION 20.5
            (NOTICE
            OF ARBITRATION; PROCESS) AND TO THE FULLEST EXTENT PERMITTED BY LAW, THE AGGREGATE LIABILITY OF THE CHAOS TO
            INTENTION ENTITIES TO YOU FOR ALL CLAIMS ARISING OUT OF OR RELATING TO THE USE OF OR ANY INABILITY TO USE
            ANY
            PORTION OF THE SERVICE OR OTHERWISE UNDER THESE TERMS, WHETHER IN CONTRACT, TORT, OR OTHERWISE, IS LIMITED
            TO THE
            GREATER OF: (A) THE AMOUNT YOU HAVE PAID TO CHAOS TO INTENTION FOR ACCESS TO AND USE OF THE SERVICE IN THE
            12
            MONTHS PRIOR TO THE EVENT OR CIRCUMSTANCE GIVING RISE TO CLAIM; OR (B) $100.&nbsp; EACH PROVISION OF THESE
            TERMS
            THAT PROVIDES FOR A LIMITATION OF LIABILITY, DISCLAIMER OF WARRANTIES, OR EXCLUSION OF DAMAGES IS INTENDED
            TO AND
            DOES ALLOCATE THE RISKS BETWEEN THE PARTIES UNDER THESE TERMS.&nbsp; THIS ALLOCATION IS AN ESSENTIAL ELEMENT
            OF
            THE BASIS OF THE BARGAIN BETWEEN THE PARTIES.&nbsp; EACH OF THESE PROVISIONS IS SEVERABLE AND INDEPENDENT OF
            ALL
            OTHER PROVISIONS OF THESE TERMS.&nbsp; THE LIMITATIONS IN THIS SECTION WILL APPLY EVEN IF ANY LIMITED REMEDY
            FAILS
            OF ITS ESSENTIAL PURPOSE.</p>

          <ol start="20">
            <li><strong> Dispute Resolution and Arbitration</strong></li>
          </ol>

          <p>20.1.&nbsp; <strong>Generally.&nbsp; </strong>In the interest of resolving disputes between you and Chaos
            To
            Intention in the most expedient and cost-effective manner, subject to all applicable laws and except as
            described
            in Section 20.2 (Exceptions) and 20.3 (Opt-Out), you and Chaos To Intention agree that every dispute arising
            in
            connection with these Terms and your use of the Service will be resolved by binding arbitration.&nbsp;
            Arbitration
            is less formal than a lawsuit in court.&nbsp; Arbitration uses a neutral arbitrator instead of a judge or
            jury,
            may allow for more limited discovery than in court, and can be subject to very limited review by
            courts.&nbsp;
            Arbitrators can award the same damages and relief that a court can award.&nbsp; This agreement to arbitrate
            disputes includes all claims arising out of or relating to any aspect of these Terms or your use of the
            Service,
            whether based in contract, tort, statute, fraud, misrepresentation, or any other legal theory, and
            regardless of
            whether a claim arises during or after the termination of these Terms.&nbsp; YOU UNDERSTAND AND AGREE THAT,
            BY
            ENTERING INTO THESE TERMS, YOU AND CHAOS TO INTENTION ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO
            PARTICIPATE IN A CLASS ACTION.&nbsp; If you reside in a country (for example, a member state of the European
            Union) with laws that give consumers the right to bring disputes in their local courts, this Section 20
            (Dispute
            Resolution and Arbitration) does not affect those requirements.</p>

          <p>20.2.&nbsp; <strong>Exceptions.&nbsp; </strong>Despite the provisions of Section 20.1 (Generally), nothing
            in
            these Terms will be deemed to waive, preclude, or otherwise limit the right of either party to: (a) bring an
            individual action in small claims court; (b) pursue an enforcement action through the applicable federal,
            state,
            or local agency if that action is available; (c) seek injunctive relief in a court of law in aid of
            arbitration;
            or (d) to file suit in a court of law to address an intellectual property infringement claim.</p>

          <p>20.3.&nbsp; <strong>Opt-Out.&nbsp; </strong>If you do not wish to resolve disputes by binding arbitration,
            you
            may opt out of the provisions of this Section 20 (Dispute Resolution and Arbitration) within 30 days after
            the
            date that you agree to these Terms by sending a letter to Chaos To Intention LLC, Attention: Legal
            Department --
            Arbitration Opt-Out, 176 Ridge Trail, Chapel Hill, NC 27516 that specifies: your full legal name, the email
            address associated with your account on the Service, and a statement that you wish to opt out of arbitration
            ("<strong>Opt-Out Notice</strong>").&nbsp; Once Chaos To Intention receives your Opt-Out Notice, this
            Section 20
            (Dispute Resolution and Arbitration) will be void and any action arising out of these Terms will be resolved
            as
            set forth in Section 21.2 (Governing Law).&nbsp; The remaining provisions of these Terms will not be
            affected by
            your Opt-Out Notice.</p>

          <p>20.4.&nbsp; <strong>Arbitrator.&nbsp; </strong>Any arbitration between you and Chaos To Intention will be
            settled
            under the Federal Arbitration Act and administered by the American Arbitration Association
            ("<strong>AAA</strong>") under its Consumer Arbitration Rules (collectively, "<strong>AAA Rules</strong>")
            as
            modified by these Terms.&nbsp; The AAA Rules and filing forms are available online at&nbsp;<a
              href="http://www.adr.org/">www.adr.org</a>, by calling the AAA at 1-800-778-7879, or by contacting Chaos
            To
            Intention.&nbsp; The arbitrator has exclusive authority to resolve any dispute relating to the
            interpretation,
            applicability, or enforceability of this binding arbitration agreement.</p>

          <p>20.5.&nbsp; <strong>Notice of Arbitration; Process.&nbsp; </strong>A party who intends to seek arbitration
            must
            first send a written notice of the dispute to the other party by certified U.S.&nbsp; Mail or by Federal
            Express
            (signature required) or, only if that other party has not provided a current physical address, then by
            electronic
            mail ("<strong>Notice of Arbitration</strong>").&nbsp; Chaos To Intention's address for Notice is: Chaos To
            Intention LLC, 176 Ridge Trail, Chapel Hill, NC 27516.&nbsp; The Notice of Arbitration must: (a) describe
            the
            nature and basis of the claim or dispute; and (b) set forth the specific relief sought
            ("<strong>Demand</strong>").&nbsp; The parties will make good faith efforts to resolve the claim directly,
            but if
            the parties do not reach an agreement to do so within 30 days after the Notice of Arbitration is received,
            you or
            Chaos To Intention may commence an arbitration proceeding.&nbsp; All arbitration proceedings between the
            parties
            will be confidential unless otherwise agreed by the parties in writing.&nbsp; During the arbitration, the
            amount
            of any settlement offer made by you or Chaos To Intention must not be disclosed to the arbitrator until
            after the
            arbitrator makes a final decision and award, if any.&nbsp; If the arbitrator awards you an amount higher
            than the
            last written settlement amount offered by Chaos To Intention in settlement of the dispute prior to the
            award,
            Chaos To Intention will pay to you the higher of: (i) the amount awarded by the arbitrator; or (ii) $10,000.
          </p>

          <p>20.6.&nbsp; <strong>Fees.&nbsp; </strong>If you commence arbitration in accordance with these Terms, Chaos
            To
            Intention will reimburse you for your payment of the filing fee, unless your claim is for more than $10,000,
            in
            which case the payment of any fees will be decided by the AAA Rules.&nbsp; Any arbitration hearing will take
            place
            at a location to be agreed upon in Orange County, North Carolina, but if the claim is for $10,000 or less,
            you may
            choose whether the arbitration will be conducted: (a) solely on the basis of documents submitted to the
            arbitrator; (b) through a non-appearance based telephone hearing; or (c) by an in-person hearing as
            established by
            the AAA Rules in the county (or parish) of your billing address.&nbsp; If the arbitrator finds that either
            the
            substance of your claim or the relief sought in the Demand is frivolous or brought for an improper purpose
            (as
            measured by the standards set forth in Federal Rule of Civil Procedure 11(b)), then the payment of all fees
            will
            be governed by the AAA Rules.&nbsp; In that case, you agree to reimburse Chaos To Intention for all monies
            previously disbursed by it that are otherwise your obligation to pay under the AAA Rules.&nbsp; Regardless
            of the
            manner in which the arbitration is conducted, the arbitrator must issue a reasoned written decision
            sufficient to
            explain the essential findings and conclusions on which the decision and award, if any, are based.&nbsp; The
            arbitrator may make rulings and resolve disputes as to the payment and reimbursement of fees or expenses at
            any
            time during the proceeding and upon request from either party made within 14 days of the arbitrator's ruling
            on
            the merits.</p>

          <p>20.7.&nbsp; <strong>No Class Actions.&nbsp; </strong>YOU AND CHAOS TO INTENTION AGREE THAT EACH MAY BRING
            CLAIMS
            AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY
            PURPORTED
            CLASS OR REPRESENTATIVE PROCEEDING.&nbsp; Further, unless both you and Chaos To Intention agree otherwise,
            the
            arbitrator may not consolidate more than one person's claims, and may not otherwise preside over any form of
            a
            representative or class proceeding.</p>

          <p>20.8.&nbsp; <strong>Modifications to this Arbitration Provision.&nbsp; </strong>If Chaos To Intention makes
            any
            future change to this arbitration provision, other than a change to Chaos To Intention's address for Notice
            of
            Arbitration, you may reject the change by sending us written notice within 30 days of the change to Chaos To
            Intention's address for Notice of Arbitration, in which case your account with Chaos To Intention will be
            immediately terminated and this arbitration provision, as in effect immediately prior to the changes you
            rejected
            will survive.</p>

          <p>20.9.&nbsp; <strong>Enforceability.&nbsp; </strong>If Section 20.7 (No Class Actions) or the entirety of
            this
            Section 20 (Dispute Resolution and Arbitration) is found to be unenforceable, or if Chaos To Intention
            receives an
            Opt-Out Notice from you, then the entirety of this Section 20 (Dispute Resolution and Arbitration) will be
            null
            and void and, in that case, exclusive jurisdiction and venue described in Section 21.2 (Governing Law) will
            govern
            any action arising out of or related to these Terms.</p>

          <ol start="21">
            <li><strong> Miscellaneous</strong></li>
          </ol>

          <p>21.1.&nbsp; <strong>General Terms.&nbsp; </strong>These Terms, together with the Privacy Policy and any
            other
            agreements expressly incorporated by reference into these Terms, are the entire and exclusive understanding
            and
            agreement between you and Chaos To Intention regarding your use of the Service.&nbsp; You may not assign or
            transfer these Terms or your rights under these Terms, in whole or in part, by operation of law or
            otherwise,
            without our prior written consent.&nbsp; We may assign these Terms at any time without notice or
            consent.&nbsp;
            The failure to require performance of any provision will not affect our right to require performance at any
            other
            time after that, nor will a waiver by us of any breach or default of these Terms, or any provision of these
            Terms,
            be a waiver of any subsequent breach or default or a waiver of the provision itself.&nbsp; Use of section
            headers
            in these Terms is for convenience only and will not have any impact on the interpretation of any
            provision.&nbsp;
            Throughout these Terms the use of the word "including" means "including but not limited to".&nbsp; If any
            part of
            these Terms is held to be invalid or unenforceable, the unenforceable part will be given effect to the
            greatest
            extent possible, and the remaining parts will remain in full force and effect.</p>

          <p>21.2.&nbsp; <strong>Governing Law.&nbsp; </strong>These Terms are governed by the laws of the State of
            North
            Carolina without regard to conflict of law principles.&nbsp; You and Chaos To Intention submit to the
            personal and
            exclusive jurisdiction of the state courts and federal courts located within Chapel Hill, North Carolina for
            resolution of any lawsuit or court proceeding permitted under these Terms.&nbsp; In addition, please note
            that
            disputes may be submitted for online resolution to the European Commission Online Dispute Resolution
            platform
            (more information can be found at ec.europa.eu/consumers/odr).&nbsp; We are incorporated under North
            Carolina law,
            and we make no representation that Materials included in the Service are appropriate or available for use in
            other
            locations.</p>

          <p>21.3.&nbsp; <strong>Privacy Policy.&nbsp; </strong>Please read the Chaos To Intention privacy policy (<a
              href="https://chaostointention.com/privacy">https://ChaosToIntention.com/privacy</a>), the
            "<strong>Privacy
              Policy</strong>", carefully for information relating to our collection, use, storage, disclosure of your
            personal information.&nbsp; Chaos To Intention's Privacy Policy is incorporated by this reference into, and
            made a
            part of, these Terms.</p>

          <p>21.4.&nbsp; <strong>Additional Terms.&nbsp; </strong>Your use of the Service is subject to all additional
            terms,
            policies, rules, or guidelines applicable to the Service or certain features of the Service that we may post
            on or
            link to from the Service (the "<strong>Additional Terms</strong>").&nbsp; All Additional Terms are
            incorporated by
            this reference into, and made a part of, these Terms.</p>

          <p>21.5.&nbsp; <strong>Consent to Electronic Communications.&nbsp; </strong>By using the Service, you consent
            to
            receiving certain electronic communications from us as further described in our Privacy Policy.&nbsp; Please
            read
            our Privacy Policy to learn more about our electronic communications practices.&nbsp; You agree that any
            notices,
            agreements, disclosures, or other communications that we send to you electronically will satisfy any legal
            communication requirements, including that those communications be in writing.</p>

          <p>21.6.&nbsp; <strong>Contact Information.&nbsp; </strong>The Service is offered by Chaos To Intention LLC,
            located
            at 176 Ridge Trail, Chapel Hill, NC 27516.&nbsp; You may contact us by sending correspondence to that
            address or
            by contacting customer service at&nbsp;<a
              href="https://chaostointention.com/contact">https://ChaosToIntention.com/contact</a>.</p>

          <p>21.7.&nbsp; <strong>Notice to California Residents.&nbsp; </strong>If you are a California resident, under
            California Civil Code Section 1789.3, you may contact the Complaint Assistance Unit of the Division of
            Consumer
            Services of the California Department of Consumer Affairs in writing at 1625 N.&nbsp; Market Blvd., Suite
            S-202,
            Sacramento, California 95834, or by telephone at (800) 952-5210 in order to resolve a complaint regarding
            the
            Service or to receive further information regarding use of the Service.</p>

          <p>21.8.&nbsp; <strong>No Support.&nbsp; </strong>We are under no obligation to provide support for the
            Service.&nbsp; In instances where we may offer support, the support will be subject to published policies.
          </p>

          <p>21.9.&nbsp; <strong>International Use.&nbsp; </strong>The Service is operated from the United States.&nbsp;
            We
            make no representation that the Service is appropriate or available for use outside of the United
            States.&nbsp;
            Access to the Service from countries or territories or by individuals where such access is illegal is
            prohibited.
          </p>
        </v-card>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
export default {
  name: "TermsOfService"
}
</script>