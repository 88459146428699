import { PROJECT_ACTIONS } from "@/helpers/actionHistory";
import userDetailsMixin from "./userDetailsMixin";
import categoriesUnoRedoHelpersMixin from "@/mixins/categoriesUndoRedoHelpersMixins";
const projectsUndoRedoHelpersMixins = {
  mixins: [userDetailsMixin, categoriesUnoRedoHelpersMixin],
  methods: {
    async handleProjectUndoAction(currAction) {
      switch (currAction.type) {
        case PROJECT_ACTIONS.EDIT:
        case PROJECT_ACTIONS.BATCH_EDIT:
          await this.restoreCategoriesData(currAction.data);
          break;
        case PROJECT_ACTIONS.DELETE:
        case PROJECT_ACTIONS.BATCH_DELETE:
          await this.handleProjectAddAction(
            currAction.data,
            this.userInfo.uid,
            false
          );
          break;
        case PROJECT_ACTIONS.ADD:
          await this.handleProjectRemoveAction(currAction.data);
          break;
      }
    },
    async handleProjectRedoAction(currAction) {
      switch (currAction.type) {
        case PROJECT_ACTIONS.EDIT:
        case PROJECT_ACTIONS.BATCH_EDIT:
          await this.restoreCategoriesData(currAction.data, true);
          break;
        case PROJECT_ACTIONS.ADD:
        case PROJECT_ACTIONS.BATCH_ADD:
          await this.handleProjectAddAction(currAction.data);
          break;
        case PROJECT_ACTIONS.BATCH_DELETE:
        case PROJECT_ACTIONS.DELETE:
          await this.handleProjectRemoveAction(currAction.data);
          // removeProject(currAction.data, this.userInfo.uid, false);
          break;
      }
    },
  },
  computed: {},
};

export default projectsUndoRedoHelpersMixins;
