import { TASK_ACTIONS } from "@/helpers/actionHistory";
import tasksHelpersMixins from "./tasksHelpersMixin";
import {
  addOrRemoveOrUpdateTasksInDb,
  addOrRemoveOrUpdateTasksInLocalTasksList,
  addRecurringTasksInfoInMap,
  areTaskRecurrenceRulesValid,
  checkIfTaskHasReminder,
  createDateTimeFromDue,
  createReminderTasks,
  createRestoreTaskUpdatesData,
  createTaskDataForGoogleCal,
  createVirtualTasksFromRecurringTasks,
  fieldRequriesProcessing,
  getDateAndTimeFromDueDate,
  getMainTaskRulesAndFillRecurringOpts,
  getRecurringTasks,
  getReminderTasksLinkedToTaskId,
  getUpdatedTaskProps,
  isCalenderPropsChanged,
  isTaskVirtual,
  processTask,
} from "@/helpers/tasks";
import EventEmitter from "@/helpers/eventEmitter";
import isEmpty from "lodash/isEmpty";
import {
  removeTaskFromGoogleCal,
  restoreTasksInGoogleCal,
  updateTasksInGoogleCal,
} from "./googleCalHelpersMixins";
import {
  REFRESH_ACTIVITY_HISTORY,
  REFRESH_FORM_DATA,
} from "@/variables/events";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  addMultiNotesInNotesList,
  removeNotesFromNotesList,
} from "./notesHelpersMixin";
import DatabaseInterface from "@/services/DatabaseInterface";
import { getCurrDate } from "@/helpers/dates";
import { cloneDeep } from "lodash";
import removeProps from "@/utils/removeProps";
import {
  RECURRENCE_TASK_PROPS,
  booleanPropsOfTask,
  orderPropsOfTask,
  taskRestoreProps,
  taskfieldWhichRequiresProcessing,
} from "@/variables/tasks";
import {
  changeCatOfProjectResources,
  getProjectResourcesFromText,
} from "@/helpers/resources";
const taskUndoRedoHelpersMixin = {
  mixins: [tasksHelpersMixins],
  methods: {
    async updateTasksListInTable(taskList, refreshList = true) {
      let reminderTasksToRemove = [];
      let reminderTasksToAdd = [];
      if (taskList && taskList.length) {
        const updates = [];
        taskList.forEach((taskToUpdate) => {
          delete taskToUpdate.changedProps;
          const taskDataKeys = Object.keys(taskToUpdate);
          const taskKey = taskToUpdate.key;
          if (
            fieldRequriesProcessing(
              taskDataKeys,
              taskfieldWhichRequiresProcessing
            )
          ) {
            const currTaskData = this.tasks.find(
              (t) => t.key === taskToUpdate.key
            );

            if (!isEmpty(currTaskData)) {
              const rawUpdatedData = {
                ...currTaskData,
                ...taskToUpdate,
              };

              let extraData = {};
              if (taskToUpdate.completed !== undefined) {
                extraData.keepItVisible = !!taskToUpdate.completed;
              }
              taskToUpdate = processTask(
                { ...rawUpdatedData, ...extraData },
                this.projects,
                this.categories
              );
              if (
                checkIfTaskHasReminder(currTaskData) &&
                this.checkIfParsedTaskDataHasReminderProps(taskDataKeys)
              ) {
                const currRemindersLinked = getReminderTasksLinkedToTaskId(
                  this.tasks,
                  taskKey
                );

                if (!isEmpty(currRemindersLinked)) {
                  reminderTasksToRemove.push(...currRemindersLinked);
                }

                if (
                  checkIfTaskHasReminder(rawUpdatedData) &&
                  !rawUpdatedData.completed
                ) {
                  reminderTasksToAdd.push(
                    ...createReminderTasks(rawUpdatedData)
                  );
                }
              }
            }
            // taskToUpdate.key
            // const rowData = this.$refs.dataGrid.getRowData(taskToUpdate.key);
          }

          updates.push({ key: taskKey, updates: taskToUpdate });
          // return taskToUpdate;
        });

        // this.$refs.dataGrid.updateRowData(updates, refreshList);

        await addOrRemoveOrUpdateTasksInLocalTasksList(
          {
            tasksToUpdate: updates,
            tasksToRemove: reminderTasksToRemove,
            tasksToAdd: reminderTasksToAdd,
            addMethod: !isEmpty(reminderTasksToAdd) ? "push" : "unshift",
            verifyAdd: isEmpty(reminderTasksToAdd),
          },
          refreshList
        );

        if (refreshList) {
          EventEmitter.emit(REFRESH_ACTIVITY_HISTORY);
        }
        // this.$refs.tasksListWrapper.updateListRow(updates, refreshList);
      }
    },
    async handleTaskUndoAction(currAction) {
      switch (currAction.type) {
        case TASK_ACTIONS.ADD: {
          await this.handleAddTaskAction(currAction.data, "undo");
          break;
        }
        case TASK_ACTIONS.BATCH_ADD:
          await this.removeTasksFromTaskList(currAction.data, false);
          break;
        case TASK_ACTIONS.EDIT:
          await this.restoreTaskData(currAction.data);
          break;
        case TASK_ACTIONS.BATCH_EDIT:
          await this.restoreMultiTaskData(currAction.data);
          break;
        case TASK_ACTIONS.BATCH_DELETE:
          this.restoreGoogleCalTasks(currAction.data);
          await this.fillTaskDataThenAddInList(currAction.data, false);
          break;
        case TASK_ACTIONS.NORMAL_RECURRING_EDIT:
          this.restoreNormalAndRecurringTaskData(currAction.data);
          break;
        case TASK_ACTIONS.NORMAL_RECURRING_DELETE:
          this.restoreRemovedNormalOrRecurringTasks(currAction.data);
          break;
        case TASK_ACTIONS.NORMAL_TO_RECURRING:
        case TASK_ACTIONS.REPEAT_EVERY_TO_NORMAL:
        case TASK_ACTIONS.REPEAT_EVERY_TO_REPEAT_IN:
          this.restoreNormalTask(currAction.data);
          break;
        case TASK_ACTIONS.ACTIONS_BATCH:
          this.handleTaskBatchActions(currAction.data);
          break;
        case TASK_ACTIONS.GROUP_OR_REORDER_BATCH:
          await this.restoreMultiTaskData(currAction.data);
          break;
        // case TASK_ACTIONS.REPEAT_EVERY_TO_REPEAT_IN:
        //   await this.handleRepeatEveryToRepeatInConvert(currAction.data);
        //   break;
        case TASK_ACTIONS.CONVERTED_TO_NOTES:
          await this.handleTasksConvertedToNotes(currAction.data);
          break;
      }
    },
    async handleTaskRedoAction(currAction) {
      switch (currAction.type) {
        case TASK_ACTIONS.ADD:
          await this.handleAddTaskAction(currAction.data, "redo");
          break;
        case TASK_ACTIONS.BATCH_ADD:
          this.restoreGoogleCalTasks(currAction.data);
          await this.fillTaskDataThenAddInList(currAction.data, false);
          break;
        case TASK_ACTIONS.EDIT:
          await this.restoreTaskData(currAction.data, true);
          break;
        case TASK_ACTIONS.BATCH_EDIT:
          await this.restoreMultiTaskData(currAction.data, true);
          break;
        case TASK_ACTIONS.BATCH_DELETE:
          await this.removeTasksFromTaskList(currAction.data, false);
          break;
        case TASK_ACTIONS.NORMAL_RECURRING_EDIT:
          await this.restoreNormalAndRecurringTaskData(currAction.data, true);
          break;
        case TASK_ACTIONS.NORMAL_RECURRING_DELETE:
          this.removeRecurringOrNormalTasksFromMap(currAction.data);
          break;
        case TASK_ACTIONS.NORMAL_TO_RECURRING:
        case TASK_ACTIONS.REPEAT_EVERY_TO_NORMAL:
        case TASK_ACTIONS.REPEAT_EVERY_TO_REPEAT_IN:
          this.restoreNormalTask(currAction.data, true);
          break;
        case TASK_ACTIONS.ACTIONS_BATCH:
          this.handleTaskBatchActions(currAction.data, true);
          break;
        case TASK_ACTIONS.GROUP_OR_REORDER_BATCH:
          await this.restoreMultiTaskData(currAction.data, true);
          break;
        // case TASK_ACTIONS.REPEAT_EVERY_TO_REPEAT_IN:
        //   await this.handleRepeatEveryToRepeatInConvert(currAction.data, true);
        //   break;
        case TASK_ACTIONS.CONVERTED_TO_NOTES:
          await this.handleTasksConvertedToNotes(currAction.data, true);
          break;
      }
    },
    async handleAddTaskAction(actionData, type = "undo") {
      let taskDataForEditForm = {};
      let tasksToAdd = [];
      let recurringTasksMap = {};
      let localTasksToRemove = [];
      let reminderTasksToRemove = [];
      if (type === "undo") {
        const recurrence = actionData.recurrence;
        const restoreOldTaskIdData = actionData.restoreOldIdData;
        await this.removeTasksFromTaskList(actionData, false);
        if (areTaskRecurrenceRulesValid(recurrence)) {
          let tasksToRemove = [];
          let localTasksToRemove = [];
          let googleTasksToRemove = [];

          const currTaskData = actionData;
          const recurringTasks = getRecurringTasks(this.tasks, "list", {
            linkedTo: currTaskData.key,
          });

          if (!isEmpty(recurringTasks)) {
            recurringTasks.forEach((t) => {
              const isTaskVirtual = t.isVirtual;
              // const isFromGoogle = t.isFromGoogle;

              const linkedReminders = getReminderTasksLinkedToTaskId(
                this.tasks,
                t.key
              );

              if (!isEmpty(linkedReminders)) {
                reminderTasksToRemove.push(...linkedReminders);
              }
              tasksToRemove.push(t);
              localTasksToRemove.push(t);
              if (!isTaskVirtual) {
                googleTasksToRemove.push(t);
              }
            });
          }

          addOrRemoveOrUpdateTasksInDb({
            tasksToRemove: tasksToRemove,
          });
          addOrRemoveOrUpdateTasksInLocalTasksList({
            tasksToRemove: [...localTasksToRemove, ...reminderTasksToRemove],
          });

          EventEmitter.emit(REFRESH_ACTIVITY_HISTORY);

          removeTaskFromGoogleCal(googleTasksToRemove);

          this.replaceRecurringTasksInfoInMap({
            list: [
              {
                mainTaskId: currTaskData.key,
                tasks: [],
              },
            ],
          });
        }

        if (!isEmpty(restoreOldTaskIdData)) {
          const taskKey = restoreOldTaskIdData.key;
          const restoreType = restoreOldTaskIdData.restoreFrom;
          let listToGetFrom =
            restoreType === "raw" ? this.rawTasks : this.tasks;
          taskDataForEditForm = listToGetFrom.find((t) => t.key === taskKey);
        }
      }

      if (type === "redo") {
        const recurrence = actionData.recurrence;
        const recurrenceRulesAreValid = areTaskRecurrenceRulesValid(recurrence);
        let dataForGoogle = { ...actionData };
        if (recurrenceRulesAreValid) {
          dataForGoogle.extraData = {
            recurrence,
          };
        }

        this.restoreGoogleCalTasks({ ...dataForGoogle });
        await this.fillTaskDataThenAddInList(actionData, false);

        const currRawData = { ...actionData };
        taskDataForEditForm = { ...currRawData };
        if (recurrenceRulesAreValid) {
          const { tasks: recurringTasks, datesList: recurringTasksList } =
            createVirtualTasksFromRecurringTasks([{ ...currRawData }]);

          taskDataForEditForm = recurringTasks[0];

          tasksToAdd.push(...recurringTasks);
          recurringTasksMap.mainTaskId = currRawData.key;
          recurringTasksMap.tasksToAdd = recurringTasksList;
          localTasksToRemove.push({ ...currRawData });

          addOrRemoveOrUpdateTasksInLocalTasksList({
            tasksToAdd,
            tasksToRemove: localTasksToRemove,
          });

          EventEmitter.emit(REFRESH_ACTIVITY_HISTORY);

          addRecurringTasksInfoInMap(
            recurringTasksMap.mainTaskId,
            recurringTasksMap.tasksToAdd
          );
        }
      }
      if (
        !isEmpty(taskDataForEditForm) &&
        this.checkIfTaskEditIsActiveInResourceForm()
      ) {
        this.refreshTaskDataInResourceForm(taskDataForEditForm.key);
      } else if (!isEmpty(taskDataForEditForm) && !isEmpty(this.editTaskData)) {
        this.setTaskData(
          processTask(getMainTaskRulesAndFillRecurringOpts(taskDataForEditForm))
        );

        await this.$nextTick();
        EventEmitter.emit(REFRESH_FORM_DATA, true);
      } else {
        EventEmitter.emit(REFRESH_ACTIVITY_HISTORY);
      }
    },
    async handleTaskBatchActions(actionDataList, isRedoAction) {
      for (const actionData of actionDataList) {
        if (isRedoAction) {
          await this.handleTaskRedoAction(actionData);
        } else {
          await this.handleTaskUndoAction(actionData);
        }
      }
    },
    async handleTasksConvertedToNotes(actionData, isRedoAction) {
      const { tasksToRemoveData, notesToAddData } = actionData;

      if (!isEmpty(tasksToRemoveData)) {
        if (isRedoAction) {
          this.removeRecurringOrNormalTasksFromMap(tasksToRemoveData);
        } else {
          this.restoreRemovedNormalOrRecurringTasks(tasksToRemoveData);
        }
      }

      if (!isEmpty(notesToAddData)) {
        if (isRedoAction) {
          addMultiNotesInNotesList(notesToAddData, false);
        } else {
          removeNotesFromNotesList(notesToAddData, false);
        }
      }
    },

    async restoreTaskData(taskData, isRedoAction) {
      const updates = {};

      let dataToUse = taskData;
      let keysFrom = taskData;
      let refreshDescrText = false;
      let refreshHistoryTable = false;
      if (isRedoAction) {
        dataToUse = { ...taskData.changedProps, key: taskData.key };
        keysFrom = taskData.changedProps;
      }

      if (taskData.updateDescrText) refreshDescrText = taskData.descrType;
      if (taskData.refreshFormData) refreshHistoryTable = true;
      const currTaskData = this.rawTasksMap[taskData.key];
      const props = Object.keys(keysFrom);
      let refreshEditForm = true;
      createRestoreTaskUpdatesData(dataToUse, updates, {
        // projects: this.projects,
        propsToRestore: props,
      });
      const updatedTaskData = isRedoAction
        ? {
            ...getUpdatedTaskProps(taskData.changedProps, props),
            key: taskData.key,
          }
        : taskData;

      this.restoreGoogleCalTasksData([
        { key: updatedTaskData.key, ...updatedTaskData },
      ]);

      const isAreaChanged = props.includes("project");
      if (
        !isEmpty(currTaskData) &&
        isAreaChanged &&
        this.projects[updatedTaskData.project]
      ) {
        const mentionedProjectResources = getProjectResourcesFromText(
          updatedTaskData.title ? updatedTaskData.title : currTaskData.title
        );

        if (!isEmpty(mentionedProjectResources)) {
          changeCatOfProjectResources([
            {
              resources: mentionedProjectResources,
              catId: updatedTaskData.project,
              isTaskRef: true,
            },
          ]);
        }
      }

      this.updateTasksListInTable([{ ...updatedTaskData }]);

      if (
        (!isEmpty(this.editTaskData) &&
          this.editTaskData.key === taskData.key) ||
        this.checkIfTaskIsActiveInResourceForm(taskData.key)
      ) {
        refreshEditForm = false;
      }

      await DatabaseInterface.update(updates, this.userInfo.uid);
      await this.$nextTick();

      this.updateEditTaskData(
        { ...updatedTaskData },
        {
          refreshFormOnly: refreshEditForm,
          refreshDescrText,
          refreshHistoryTable,
          isAreaChanged: false,
        }
      );
    },
    async updateEditTaskData(
      taskDataToUpdate,
      { refreshFormOnly, refreshDescrText, refreshHistoryTable, isAreaChanged }
    ) {
      if (!isEmpty(taskDataToUpdate)) {
        delete taskDataToUpdate.changedProps;
        const taskKey = taskDataToUpdate.key;
        if (!refreshFormOnly) {
          const currTaskData = this.tasks.find((t) => t.key === taskKey);
          if (!isEmpty(currTaskData)) {
            if (this.checkIfTaskEditIsActiveInResourceForm()) {
              this.refreshTaskDataInResourceForm(taskKey);
            } else if (!isEmpty(this.editTaskData)) {
              if (isAreaChanged && currTaskData.project) {
                this.setTaskAreaInFormAndRefresh(currTaskData.project);
              } else {
                const createdData = {
                  ...currTaskData,
                  ...taskDataToUpdate,
                };
                const processedTask = {
                  ...processTask(
                    getMainTaskRulesAndFillRecurringOpts(createdData)
                  ),
                };

                this.setTaskData({
                  ...processedTask,
                });
                await this.$nextTick();
                EventEmitter.emit(
                  REFRESH_FORM_DATA,
                  true,
                  refreshDescrText,
                  refreshHistoryTable
                );
              }
            }

            // if (isEqual(processedTask, currTaskData)) {
            //   EventEmitter.emit(REFRESH_FORM_DATA);
            // }
          }

          //
        } else if (refreshFormOnly) {
          if (this.checkIfTaskIsActiveInResourceForm(taskKey)) {
            this.refreshTaskDataInResourceForm(taskKey);
          } else {
            EventEmitter.emit(
              REFRESH_FORM_DATA,
              true,
              refreshDescrText,
              refreshHistoryTable
            );
          }

          // if (isEmpty(this.editTaskData)) {
          //   EventEmitter.emit(REFRESH_ACTIVITY_HISTORY);
          // }
        } else {
          // EventEmitter.emit(REFRESH_ACTIVITY_HISTORY);
        }
      }
    },
    async restoreMultiTaskData(taskList, isRedoAction) {
      const updates = {};
      const tasksUpdates = [];
      let taskDataForLocalEditTask = {};
      if (isRedoAction) {
        taskList.forEach((taskData) => {
          const props = Object.keys(taskData.changedProps);
          createRestoreTaskUpdatesData(
            { ...taskData.changedProps, key: taskData.key },
            updates,
            {
              projects: this.projects,
              propsToRestore: props,
            }
          );

          const createdData = {
            ...getUpdatedTaskProps(taskData.changedProps, props),
            key: taskData.key,
          };

          if (
            (!isEmpty(this.editTaskData) &&
              this.editTaskData.key === taskData.key) ||
            this.checkIfTaskIsActiveInResourceForm(taskData.key)
          ) {
            taskDataForLocalEditTask = { ...createdData };
          }
          tasksUpdates.push({
            ...createdData,
          });
        });
      } else {
        taskList.forEach((taskData) => {
          const props = Object.keys(taskData);
          createRestoreTaskUpdatesData(taskData, updates, {
            projects: this.projects,
            propsToRestore: props,
          });
          const createdData = {
            ...taskData,
            key: taskData.key,
          };

          if (
            (!isEmpty(this.editTaskData) &&
              this.editTaskData.key === taskData.key) ||
            this.checkIfTaskIsActiveInResourceForm(taskData.key)
          ) {
            taskDataForLocalEditTask = { ...createdData };
          }

          tasksUpdates.push({ ...taskData });
        });
      }

      this.restoreGoogleCalTasksData(tasksUpdates);
      this.updateTasksListInTable(tasksUpdates);
      this.updateEditTaskData(taskDataForLocalEditTask);
      await DatabaseInterface.update(updates, this.userInfo.uid);
    },

    async restoreNormalAndRecurringTaskData(groupDataMap, isRedoAction) {
      let dbUpdates = {};
      let taskUpdates = [];
      let tasksToAdd = [];
      let tasksToAddLocally = [];
      let updateMainRecurringGoogleTasks = [];
      let googleTasksUpdate = [];
      let tasksToRemoveFromList = [];
      let updateRecurringTasksInLocalList = [];
      // let refreshEditForm = false;
      let taskDataForLocalEditTask = {};
      let refreshDescrText = false;
      let refreshHistoryTable = false;
      const recurrenceTasksMapList = [];
      const projectResourcesCatChangeMap = {};
      for (const groupId in groupDataMap) {
        const groupData = groupDataMap[groupId];

        if (groupId !== "normal") {
          // For recurring
          const {
            editedTasks,
            updateMain,
            editedData,
            recreateTasks,
            updateVirtualTasks,
          } = groupData;
          if (!isEmpty(editedTasks)) {
            editedTasks.forEach((editedTaskData) => {
              const dataToUse = isRedoAction
                ? { ...editedTaskData.changedProps, key: editedTaskData.key }
                : editedTaskData;

              const doNotUpdateModified = editedTaskData.doNotUpdateModified;

              if (editedTaskData.updateDescrText)
                refreshDescrText = editedTaskData.descrType;
              if (editedTaskData.refreshFormData) refreshHistoryTable = true;

              const props = Object.keys(dataToUse);
              const createdUpdateObj = {
                ...getUpdatedTaskProps(dataToUse, props),
                key: editedTaskData.key,
              };

              const isAreaChangeIncluded = props.includes("project");

              if (
                isAreaChangeIncluded &&
                dataToUse.project &&
                !editedTaskData.isVirtual &&
                this.rawTasksMap[editedTaskData.key]
              ) {
                const mentionedProjectResources = getProjectResourcesFromText(
                  dataToUse.title
                    ? dataToUse.title
                    : this.rawTasksMap[editedTaskData.key].title
                );

                if (!projectResourcesCatChangeMap[dataToUse.project]) {
                  projectResourcesCatChangeMap[dataToUse.project] = {
                    catId: dataToUse.project,
                    resources: [],
                    isTaskRef: true,
                  };
                }

                if (projectResourcesCatChangeMap[dataToUse.project]) {
                  projectResourcesCatChangeMap[
                    dataToUse.project
                  ].resources.push(...mentionedProjectResources);
                }

                // if (!isEmpty(mentionedProjectResources)) {
                //   changeCatOfProjectResources({
                //     resources: mentionedProjectResources,
                //     catId: updatedData.project,
                //     isTaskRef: true,
                //   });
                // }
              }

              if (!doNotUpdateModified && !createdUpdateObj.modified) {
                createdUpdateObj.modified = getCurrDate();
              }
              if (!editedTaskData.isVirtual) {
                createRestoreTaskUpdatesData(dataToUse, dbUpdates, {
                  projects: this.projects,
                  propsToRestore: props,
                  doNotUpdateModified,
                });
                googleTasksUpdate.push({
                  ...createdUpdateObj,
                  isVirtual: editedTaskData.isVirtual,
                });
              }

              if (
                (!isEmpty(this.editTaskData) &&
                  this.editTaskData.key === editedTaskData.key) ||
                this.checkIfTaskIsActiveInResourceForm(editedTaskData.key)
              ) {
                const currTaskData = this.tasks.find(
                  (t) => t.key === editedTaskData.key
                );

                if (!isEmpty(currTaskData)) {
                  taskDataForLocalEditTask = {
                    ...this.rawTasksMap[editedTaskData.key],
                    ...createdUpdateObj,
                    key: editedTaskData.key,
                  };
                }
              }

              taskUpdates.push({ ...createdUpdateObj });
            });
          }

          if (updateMain) {
            const dataToUse = isRedoAction
              ? { ...editedData.changedProps, key: groupId }
              : { ...editedData, key: groupId };
            const doNotUpdateModified = editedData.doNotUpdateModified;
            const currMainRawData = { ...this.rawTasksMap[groupId] };
            let localExtraData = {};

            const props = Object.keys(dataToUse);

            createRestoreTaskUpdatesData(dataToUse, dbUpdates, {
              projects: this.projects,
              propsToRestore: props,
              restoreRecurrence: true,
              doNotUpdateModified,
            });

            const isAreaChangeIncluded = props.includes("project");

            if (
              isAreaChangeIncluded &&
              dataToUse.project &&
              this.rawTasksMap[groupId]
            ) {
              const mentionedProjectResources = getProjectResourcesFromText(
                dataToUse.title
                  ? dataToUse.title
                  : this.rawTasksMap[groupId].title
              );

              if (!projectResourcesCatChangeMap[dataToUse.project]) {
                projectResourcesCatChangeMap[dataToUse.project] = {
                  catId: dataToUse.project,
                  resources: [],
                  isTaskRef: true,
                };
              }

              if (projectResourcesCatChangeMap[dataToUse.project]) {
                projectResourcesCatChangeMap[dataToUse.project].resources.push(
                  ...mentionedProjectResources
                );
              }
            }

            const createdData = {
              ...getUpdatedTaskProps(dataToUse, props, true),
              key: groupId,
            };

            if (!doNotUpdateModified) {
              const currModifiedDate = getCurrDate();
              if (!createdData.modified) {
                createdData.modified = currModifiedDate;
              }

              if (!dataToUse.modified) {
                dataToUse.modified = currModifiedDate;
              }
            }

            if (createdData.completed !== undefined) {
              localExtraData = {
                keepItVisible:
                  !currMainRawData.completed && createdData.completed,
              };
            }

            updateMainRecurringGoogleTasks.push({
              ...createdData,
            });

            if (
              this.rawTasksMap[groupId] &&
              this.rawTasksMap[groupId].key &&
              ((!isEmpty(this.editTaskData) &&
                this.editTaskData.key === groupId) ||
                this.checkIfTaskIsActiveInResourceForm(groupId)) &&
              isEmpty(taskDataForLocalEditTask)
            ) {
              taskDataForLocalEditTask = {
                ...this.rawTasksMap[groupId],
                ...createdData,
                ...localExtraData,
              };
            }
            if (
              recreateTasks &&
              !updateVirtualTasks &&
              this.rawTasksMap[groupId]
            ) {
              const currRecurringTasks = getRecurringTasks(this.tasks, "list", {
                linkedTo: groupId,
                filter: true,
                filterOpts: [isTaskVirtual],
              });

              tasksToRemoveFromList.push(...currRecurringTasks);

              currRecurringTasks.forEach((cR) => {
                const linkedReminders = getReminderTasksLinkedToTaskId(
                  this.tasks,
                  cR.key
                );

                if (!isEmpty(linkedReminders)) {
                  tasksToRemoveFromList.push(...linkedReminders);
                }
              });
              const { tasks: virtualTasks, datesList } =
                createVirtualTasksFromRecurringTasks([
                  {
                    ...this.rawTasksMap[groupId],
                    ...dataToUse,
                    ...localExtraData,
                  },
                ]);

              if (
                isEmpty(taskDataForLocalEditTask) &&
                !isEmpty(this.editTaskData)
              ) {
                taskDataForLocalEditTask = virtualTasks[0];
              }
              recurrenceTasksMapList.push({
                mainTaskId: groupId,
                tasks: datesList,
              });
              tasksToAddLocally.push(...virtualTasks);
            }

            if (
              updateVirtualTasks &&
              this.rawTasksMap[groupId] &&
              !isEmpty(this.recurringTasksMap[groupId])
            ) {
              let clonedDataToUse = cloneDeep(dataToUse);
              clonedDataToUse = removeProps(clonedDataToUse, [
                "linkedTo",
                "recurrence",
                "key",
                "googleEventId",
                "isFirstTask",
                "isFromGoogle",
                "originalDate",
              ]);
              const currRecurringTasks = getRecurringTasks(this.tasks, "list", {
                linkedTo: groupId,
                filter: true,
                filterOpts: [isTaskVirtual],
              });

              currRecurringTasks.forEach((taskData) => {
                const createdData = {
                  ...clonedDataToUse,
                  key: taskData.key,
                  ...getDateAndTimeFromDueDate(
                    taskData.dueAsDate,
                    undefined,
                    false
                  ),
                  ...localExtraData,
                };

                const localUpdatedData = {
                  ...taskData,
                  ...createdData,
                };

                if (
                  isEmpty(taskDataForLocalEditTask) &&
                  !isEmpty(this.editTaskData) &&
                  this.editTaskData.key === taskData.key
                ) {
                  taskDataForLocalEditTask = { ...localUpdatedData };
                }

                if (
                  checkIfTaskHasReminder(taskData) &&
                  this.checkIfParsedTaskDataHasReminderProps(
                    Object.keys(clonedDataToUse)
                  )
                ) {
                  const currRemindersLinked = getReminderTasksLinkedToTaskId(
                    this.tasks,
                    taskData.key
                  );

                  if (!isEmpty(currRemindersLinked)) {
                    tasksToRemoveFromList.push(...currRemindersLinked);
                  }

                  if (
                    checkIfTaskHasReminder(localUpdatedData) &&
                    !localUpdatedData.completed
                  ) {
                    tasksToAddLocally.push(
                      ...createReminderTasks(localUpdatedData)
                    );
                  }
                }
                updateRecurringTasksInLocalList.push({
                  updates: {
                    ...localUpdatedData,
                  },
                  key: taskData.key,
                });
              });
            }
          }
        } else {
          const { editedTasks, addedTasks } = groupData;

          if (!isEmpty(editedTasks)) {
            editedTasks.forEach((editedTaskData) => {
              const dataToUse = isRedoAction
                ? { ...editedTaskData.changedProps, key: editedTaskData.key }
                : editedTaskData;

              const doNotUpdateModified = editedTaskData.doNotUpdateModified;

              if (!refreshDescrText && editedTaskData.updateDescrText)
                refreshDescrText = editedTaskData.descrType;

              if (!refreshHistoryTable && editedTaskData.refreshFormData)
                refreshHistoryTable = true;
              const props = Object.keys(dataToUse);
              createRestoreTaskUpdatesData(dataToUse, dbUpdates, {
                projects: this.projects,
                propsToRestore: props,
                doNotUpdateModified,
              });
              const createdUpdateObj = {
                ...getUpdatedTaskProps(dataToUse, props),
                key: editedTaskData.key,
              };

              const isAreaChangeIncluded = props.includes("project");

              if (
                isAreaChangeIncluded &&
                dataToUse.project &&
                this.rawTasksMap[editedTaskData.key]
              ) {
                const mentionedProjectResources = getProjectResourcesFromText(
                  dataToUse.title
                    ? dataToUse.title
                    : this.rawTasksMap[editedTaskData.key].title
                );

                if (!projectResourcesCatChangeMap[dataToUse.project]) {
                  projectResourcesCatChangeMap[dataToUse.project] = {
                    catId: dataToUse.project,
                    resources: [],
                    isTaskRef: true,
                  };
                }

                if (projectResourcesCatChangeMap[dataToUse.project]) {
                  projectResourcesCatChangeMap[
                    dataToUse.project
                  ].resources.push(...mentionedProjectResources);
                }
              }

              if (!doNotUpdateModified && !createdUpdateObj.modified) {
                createdUpdateObj.modified = getCurrDate();
              }

              if (
                isEmpty(taskDataForLocalEditTask) &&
                ((!isEmpty(this.editTaskData) &&
                  this.editTaskData.key === editedTaskData.key) ||
                  this.checkIfTaskIsActiveInResourceForm(editedTaskData.key)) &&
                this.rawTasksMap[editedTaskData.key] &&
                this.rawTasksMap[editedTaskData.key].key
              ) {
                taskDataForLocalEditTask = {
                  ...this.rawTasksMap[editedTaskData.key],
                  ...createdUpdateObj,
                };
              }
              googleTasksUpdate.push({ ...createdUpdateObj });
              taskUpdates.push({
                ...createdUpdateObj,
              });
            });
          }

          if (!isEmpty(addedTasks)) {
            tasksToAdd = [...tasksToAdd, ...addedTasks];
          }

          // For normal tasks;
        }
      }

      if (!isEmpty(tasksToAdd)) {
        if (isRedoAction) {
          this.restoreGoogleCalTasks(tasksToAdd.data);
          this.fillTaskDataThenAddInList(tasksToAdd.data, false);
        } else {
          this.removeTasksFromTaskList(tasksToAdd, false);
        }
      }

      this.restoreGoogleCalTasksData(googleTasksUpdate);

      if (!isEmpty(updateMainRecurringGoogleTasks)) {
        this.restoreGoogleCalTasksData(updateMainRecurringGoogleTasks, "raw");
      }

      const refreshList =
        isEmpty(updateRecurringTasksInLocalList) && isEmpty(tasksToAddLocally);

      this.updateTasksListInTable(taskUpdates, refreshList);
      addOrRemoveOrUpdateTasksInLocalTasksList({
        tasksToAdd: tasksToAddLocally,
        tasksToRemove: tasksToRemoveFromList,
        tasksToUpdate: updateRecurringTasksInLocalList,
        addMethod: !isEmpty(tasksToAddLocally) ? "push" : "unshift",
        verifyAdd: !!isEmpty(tasksToAddLocally),
      });

      EventEmitter.emit(REFRESH_ACTIVITY_HISTORY);

      this.replaceRecurringTasksInfoInMap({
        list: recurrenceTasksMapList,
      });

      changeCatOfProjectResources(Object.values(projectResourcesCatChangeMap));
      await DatabaseInterface.update(dbUpdates, this.userInfo.uid);
      await this.$nextTick();

      if (this.checkIfTaskEditIsActiveInResourceForm()) {
        let taskKeyToUse = this.getStoredTaskDataInResourceForm();
        if (
          this.checkIfTaskIsActiveInResourceForm(taskDataForLocalEditTask.key)
        ) {
          taskKeyToUse = taskDataForLocalEditTask.key;
        }
        this.refreshTaskDataInResourceForm(taskKeyToUse);
      } else if (
        !isEmpty(this.editTaskData) &&
        !isEmpty(taskDataForLocalEditTask)
      ) {
        const currTaskData = this.tasks.find(
          (t) => t.key === taskDataForLocalEditTask.key
        );

        if (!isEmpty(currTaskData)) {
          const processedTask = processTask(
            getMainTaskRulesAndFillRecurringOpts(taskDataForLocalEditTask)
          );
          this.setTaskData(processedTask);
          await this.$nextTick();
          EventEmitter.emit(
            REFRESH_FORM_DATA,
            true,
            refreshDescrText,
            refreshHistoryTable
          );
        }
      } else if (!isEmpty(this.editTaskData)) {
        EventEmitter.emit(
          REFRESH_FORM_DATA,
          true,
          refreshDescrText,
          refreshHistoryTable
        );
      }
    },

    async restoreNormalTask(normalTaskData, isRedoAction) {
      // const dbUpdates = {};

      if (this.rawTasksMap[normalTaskData.key]) {
        const currRawData = this.rawTasksMap[normalTaskData.key];
        const tasksToRemove = [];
        const localTasksToRemove = [];
        const googleTasksToRemove = [];
        const googleTasksToUpdate = [];
        const tasksToAdd = [];
        let taskDataForEditForm = {};
        const dataToUse = isRedoAction
          ? { ...normalTaskData.changedProps, key: normalTaskData.key }
          : normalTaskData;

        const props = Object.keys(dataToUse);
        const taskDataUpdates = {};
        const recurringTasksMap = {};

        const projectResourcesCatChangeMap = {};
        const isAreaChangeIncluded = props.includes("project");
        if (
          isAreaChangeIncluded &&
          dataToUse.project &&
          this.rawTasksMap[normalTaskData.key]
        ) {
          const mentionedProjectResources = getProjectResourcesFromText(
            dataToUse.title
              ? dataToUse.title
              : this.rawTasksMap[normalTaskData.key].title
          );

          if (!projectResourcesCatChangeMap[dataToUse.project]) {
            projectResourcesCatChangeMap[dataToUse.project] = {
              catId: dataToUse.project,
              resources: [],
              isTaskRef: true,
            };
          }

          if (projectResourcesCatChangeMap[dataToUse.project]) {
            projectResourcesCatChangeMap[dataToUse.project].resources.push(
              ...mentionedProjectResources
            );
          }
        }
        props.forEach((p) => {
          if (RECURRENCE_TASK_PROPS.includes(p) && dataToUse[p] !== undefined) {
            // dbUpdates[`/tasks/${normalTaskData.key}/${p}`] = dataToUse[p];
            taskDataUpdates[p] = dataToUse[p];
          } else if (p === "repeatInfo" && dataToUse[p] !== undefined) {
            taskDataUpdates[p] = dataToUse[p];
          } else if (
            booleanPropsOfTask.includes(p) &&
            typeof dataToUse[p] === "boolean"
          ) {
            taskDataUpdates[p] = dataToUse[p];
          } else if (
            taskRestoreProps.includes(p) &&
            dataToUse[p] !== undefined
          ) {
            if (orderPropsOfTask.includes(p)) {
              taskDataUpdates[p] =
                dataToUse[p] !== undefined ? dataToUse[p] : 0;
            } else {
              taskDataUpdates[p] = dataToUse[p] || "";
            }
          }
        });
        const createdUpdateObj = {
          ...taskDataUpdates,
          key: normalTaskData.key,
        };

        if (currRawData.googleEventId) {
          const updatedObj = {
            ...currRawData,
            ...createdUpdateObj,
          };

          updatedObj.dueAsDate = createDateTimeFromDue(
            updatedObj.due,
            updatedObj.time
          );
          googleTasksToUpdate.push({
            key: currRawData.googleEventId,
            updates: {
              ...createTaskDataForGoogleCal(updatedObj, {
                ...currRawData,
                dueAsDate: createDateTimeFromDue(
                  currRawData.due,
                  currRawData.time
                ),
                taskKey: currRawData.key,
              }),
            },
          });
        }

        this.restoreGoogleCalTasksData([
          { key: createdUpdateObj.key, ...createdUpdateObj },
        ]);

        // this.updateTasksInTable([{ ...createdUpdateObj }]);

        const areRecurrenceRulesValid = areTaskRecurrenceRulesValid(
          createdUpdateObj.recurrence
        );

        if (areRecurrenceRulesValid) {
          // if (this.rawTasksMap[normalTaskData.key]) {
          const currTaskData = this.rawTasksMap[normalTaskData.key];

          const { tasks: recurringTasks, datesList: recurringTasksList } =
            createVirtualTasksFromRecurringTasks([
              { ...currTaskData, ...createdUpdateObj },
            ]);

          taskDataForEditForm = recurringTasks[0];
          tasksToAdd.push(...recurringTasks);
          recurringTasksMap.mainTaskId = normalTaskData.key;
          recurringTasksMap.tasksToAdd = recurringTasksList;
          localTasksToRemove.push({ ...currRawData });
          // }
        } else {
          const recurringTasks = getRecurringTasks(this.tasks, "list", {
            linkedTo: normalTaskData.key,
          });

          taskDataForEditForm = processTask(
            getMainTaskRulesAndFillRecurringOpts({
              ...currRawData,
              ...createdUpdateObj,
              key: normalTaskData.key,
            })
          );

          if (!isEmpty(recurringTasks)) {
            recurringTasks.forEach((t) => {
              const linkedReminders = getReminderTasksLinkedToTaskId(
                this.tasks,
                t.key
              );
              const isTaskVirtual = t.isVirtual;
              // const isFromGoogle = t.isFromGoogle;
              tasksToRemove.push(t);
              localTasksToRemove.push(t);

              if (!isEmpty(linkedReminders)) {
                localTasksToRemove.push(...linkedReminders);
              }
              if (!isTaskVirtual) {
                googleTasksToRemove.push(t);
                // if (isFromGoogle) {
                //   googleIdToUse = createGoogleEventIdByDateAndTaskId(
                //     currTaskData.googleEventId.split("_")[0],
                //     currTaskData.dueAsDate
                //   );
                // } else {
                //   googleIdToUse = createGoogleEventIdByDateAndTaskId(
                //     currTaskData.linkedTo,
                //     currTaskData.dueAsDate
                //   );
                // }
              }
            });
          }

          tasksToAdd.push({
            ...currRawData,
            ...createdUpdateObj,
            key: normalTaskData.key,
          });

          const createdReminderTasks = createReminderTasks({
            ...currRawData,
            ...createdUpdateObj,
            key: normalTaskData.key,
          });

          tasksToAdd.push(...createdReminderTasks);
        }

        addOrRemoveOrUpdateTasksInDb({
          tasksToRemove: tasksToRemove,
          tasksToUpdate: [
            {
              key: normalTaskData.key,
              updates: taskDataUpdates,
            },
          ],
        });

        changeCatOfProjectResources(
          Object.values(projectResourcesCatChangeMap)
        );

        if (this.checkIfTaskEditIsActiveInResourceForm()) {
          this.refreshTaskDataInResourceForm(taskDataForEditForm.key);
        } else if (!isEmpty(this.editTaskData)) {
          this.setTaskData({ ...taskDataForEditForm });

          await this.$nextTick();
          EventEmitter.emit(REFRESH_FORM_DATA, true);
        }

        if (!isEmpty(googleTasksToUpdate)) {
          updateTasksInGoogleCal(googleTasksToUpdate);
        }

        await addOrRemoveOrUpdateTasksInLocalTasksList({
          tasksToAdd,
          tasksToRemove: localTasksToRemove,
        });

        if (isEmpty(this.editTaskData)) {
          EventEmitter.emit(REFRESH_ACTIVITY_HISTORY);
        }

        if (!isEmpty(recurringTasksMap)) {
          addRecurringTasksInfoInMap(
            recurringTasksMap.mainTaskId,
            recurringTasksMap.tasksToAdd
          );
        } else {
          this.replaceRecurringTasksInfoInMap({
            list: [
              {
                mainTaskId: normalTaskData.key,
                tasks: [],
              },
            ],
          });
        }
      }
    },

    async restoreGoogleCalTasksData(tasksData, listTypeToUse = "processed") {
      if (!Array.isArray(tasksData)) tasksData = [tasksData];
      const restoreTasksDataList = [];
      tasksData.forEach((tD) => {
        let listToUse = this.tasks;

        if (listTypeToUse === "raw") {
          listToUse = this.rawTasks;
        }

        const currTaskData = listToUse.find((t) => t.key === tD.key);
        if (!isEmpty(currTaskData)) {
          if (currTaskData.googleEventId) {
            let googleIdToUse = currTaskData.googleEventId;

            // if (tD.isVirtual) {
            //   if (currTaskData.isFromGoogle) {
            //     googleIdToUse = createGoogleEventIdByDateAndTaskId(
            //       currTaskData.googleEventId.split("_")[0],
            //       currTaskData.dueAsDate
            //     );
            //   } else {
            //     googleIdToUse = createGoogleEventIdByDateAndTaskId(
            //       currTaskData.linkedTo,
            //       currTaskData.dueAsDate
            //     );
            //   }
            // }
            const createdUpdates = tD;

            if (createdUpdates.due) {
              createdUpdates.dueAsDate = createDateTimeFromDue(
                createdUpdates.due,
                createdUpdates.time
              );
            }
            if (isCalenderPropsChanged(createdUpdates, currTaskData)) {
              restoreTasksDataList.push({
                key: googleIdToUse,
                updates: {
                  ...createTaskDataForGoogleCal(
                    { ...createdUpdates, taskKey: currTaskData.key },
                    currTaskData
                  ),
                },
                currData: {
                  ...currTaskData,
                },
              });
            }
          }
        }
      });

      await updateTasksInGoogleCal(restoreTasksDataList, false, true);
    },
    restoreGoogleCalTasks(tasks) {
      if (this.isGoogleCalSyncEnabled) {
        restoreTasksInGoogleCal(tasks);
      }
    },
    ...mapActions("editForm", ["showEditTaskDialog", "setTaskData"]),
    // ...mapActions("listOpts", ["storePriorityTypeOpts", "storeProjectsOpts"]),
    ...mapActions("toast", ["showToast"]),
    ...mapActions("user", ["setUserDetails"]),
    ...mapActions("task", [
      "storeProjects",
      "storeCategories",
      "storeTasks",
      "replaceRecurringTasksInfoInMap",
      "updateSelectedTasks",
    ]),
    // ...mapActions([
    //   "setGroupMode",
    //   "toggleInboxView",
    //   "changeNav",
    // ]),
    ...mapMutations("task", ["updateState"]),
    ...mapActions(["updateRootState"]),
  },
  computed: {
    ...mapGetters("task", [
      "projects",
      "categories",
      "objectivesMap",
      "rawTasks",
      "tasks",
      "rawTasksMap",
      "recurringTasksMap",
      "selectedTasks",
      "isInspectModeEnabled",
      "isFilterBarOptsEnabled",
      "currNav",
    ]),
    ...mapGetters("editForm", {
      isEditTaskFormOpen: "isOpen",
      editTaskData: "taskData",
    }),
    ...mapGetters("resourceInfo", {
      isResourceInfoViewOpen: "isOpen",
      editedResourceData: "data",
    }),
    ...mapGetters(["isGoogleCalSyncEnabled"]),
  },
};

export default taskUndoRedoHelpersMixin;
