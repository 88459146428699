import { ACTION_ON_TYPES, getUserActions } from "@/helpers/actionHistory";
import { isRedoHotKeys, isUndoHotKeys } from "@/utils/hotKeys";
import taskUndoRedoHelpersMixin from "./taskUndoRedoHelpersMixin";
import notesUndoRedoHelpersMixin from "@/mixins/notesUndoAndRedoHelpersMixin";
import projectsUndoRedoHelpersMixins from "./projectsUndoRedoHelpersMixins";
import categoriesUndoRedoHelpersMixin from "./categoriesUndoRedoHelpersMixins";
import resourcesCateUndRedoHelpersMixin from "@/mixins/resourcesCateUndoRedoHelpersMixin";
import directoryUndoRedoHelpersMixin from "./directoryUndoAndRedoHelpers";
import resourcesUndoRedoHelpersMixin from "./resourcesUndoRedoHelpersMixin";
import { REDO_EVENT, UNDO_EVENT } from "@/variables/events";

import EventEmitter from "@/helpers/eventEmitter";

const noteDirectoryActionAllowedModes = ["notes"];
const userActionsUndoRedoMixin = {
  mixins: [
    taskUndoRedoHelpersMixin,
    notesUndoRedoHelpersMixin,
    projectsUndoRedoHelpersMixins,
    categoriesUndoRedoHelpersMixin,
    directoryUndoRedoHelpersMixin,
    resourcesCateUndRedoHelpersMixin,
    resourcesUndoRedoHelpersMixin,
  ],
  methods: {
    setUserUndoRedoEventListeners() {
      EventEmitter.on(REDO_EVENT, this.handleRedoAction);
      EventEmitter.on(UNDO_EVENT, this.handleUndoAction);
    },
    removeUserUndoRedoEventListeners() {
      EventEmitter.off(REDO_EVENT, this.handleRedoAction);
      EventEmitter.off(UNDO_EVENT, this.handleUndoAction);
    },
    handleUndoHotKeys(e) {
      if (!isUndoHotKeys(e)) return;
      e.stopImmediatePropagation();
      this.handleUndoAction();
    },
    async handleRedoHotKeys(e) {
      if (!isRedoHotKeys(e)) return;

      e.stopImmediatePropagation();
      await this.handleRedoAction();
    },

    handleUndoRedoKeys(e) {
      this.handleUndoHotKeys(e);
      this.handleRedoHotKeys(e);
    },
    async handleUndoAction() {
      let actions = getUserActions().getActions();
      const currAction = actions[0];
      let actionInUse = true;
      const currRoute = this.$route.name.toLowerCase();
      if (!currAction) {
        this.showToast("No action to undo!");
        return;
      }

      getUserActions().storeActionInHistory({ ...currAction });
      switch (currAction.on) {
        case ACTION_ON_TYPES.TASK:
          this.handleTaskUndoAction(currAction);
          break;
        case ACTION_ON_TYPES.PROJECT:
          this.handleProjectUndoAction(currAction);
          break;
        case ACTION_ON_TYPES.CATEGORY:
          this.handleCategoryUndoAction(currAction);
          break;
        case ACTION_ON_TYPES.NOTES:
          this.handleUndoOnNotes(currAction);
          //   if (currAction.type === NOTES_ACTIONS.CONVERTED_TO_TASKS) {
          //     this.handleNotesConvertedToTasks(currAction.data);
          //   } else {
          //     actionInUse = false;
          //   }
          break;
        case ACTION_ON_TYPES.DIRECTORY:
          if (noteDirectoryActionAllowedModes.includes(currRoute)) {
            await this.handleUndoOnDirectory(currAction);
          } else {
            actionInUse = false;
          }

          break;
        case ACTION_ON_TYPES.RESOURCE:
          this.handleResourceUndoAction(currAction);
          break;

        case ACTION_ON_TYPES.RESOURCES_CATEGORY:
          this.handleUndoOnResrCate(currAction);
          break;
        case ACTION_ON_TYPES.ALL:
          this.handleAllBatchAction(currAction.data);
          break;
        default:
          actionInUse = false;
      }

      if (actionInUse) {
        getUserActions().removeActionByIndex(0);
      }
    },
    async handleRedoAction() {
      const actions = [...getUserActions().getActionsHistory()];
      const currAction = actions[0];
      const currRoute = this.$route.name.toLowerCase();
      let actionInUse = true;
      if (!currAction) {
        this.showToast("No action to redo!");
        return;
      }

      switch (currAction.on) {
        case ACTION_ON_TYPES.TASK:
          await this.handleTaskRedoAction(currAction);
          break;
        case ACTION_ON_TYPES.PROJECT:
          await this.handleProjectRedoAction(currAction);
          break;
        case ACTION_ON_TYPES.CATEGORY:
          await this.handleCategoryRedoAction(currAction);
          break;
        case ACTION_ON_TYPES.NOTES:
          await this.handleRedoOnNotes(currAction);
          break;
        case ACTION_ON_TYPES.DIRECTORY:
          if (noteDirectoryActionAllowedModes.includes(currRoute)) {
            await this.handleRedoOnDirectory(currAction);
          }
          break;
        case ACTION_ON_TYPES.RESOURCE:
          await this.handleResourceRedoAction(currAction);
          break;
        case ACTION_ON_TYPES.RESOURCES_CATEGORY:
          await this.handleRedoOnResrCate(currAction);
          break;
        case ACTION_ON_TYPES.ALL:
          await this.handleAllBatchAction(currAction.data, true);
          break;
        default:
          actionInUse = false;
      }

      if (actionInUse) {
        getUserActions().removeActionFromHistoryByIndex(0);
      }
    },
    async handleAllBatchAction(allActionData, isRedoAction) {
      for (const actionData of allActionData) {
        if (actionData.on === ACTION_ON_TYPES.TASK) {
          if (isRedoAction) {
            await this.handleTaskRedoAction(actionData);
          } else {
            await this.handleTaskUndoAction(actionData);
          }
        }
        if (actionData.on === ACTION_ON_TYPES.PROJECT) {
          if (isRedoAction) {
            await this.handleProjectRedoAction(actionData);
          } else {
            await this.handleProjectUndoAction(actionData);
          }
        }

        if (actionData.on === ACTION_ON_TYPES.CATEGORY) {
          if (isRedoAction) {
            await this.handleCategoryRedoAction(actionData);
          } else {
            await this.handleCategoryUndoAction(actionData.data);
          }
        }

        if (actionData.on === ACTION_ON_TYPES.OBJECTIVE) {
          if (isRedoAction) {
            await this.handleObjectiveRedoAction(actionData);
          } else {
            await this.handleObjectiveUndoAction(actionData);
          }
        }

        if (actionData.on === ACTION_ON_TYPES.RESOURCE) {
          if (isRedoAction) {
            this.handleResourceRedoAction(actionData);
          } else {
            this.handleResourceUndoAction(actionData);
          }
        }
      }
    },
  },
};

export default userActionsUndoRedoMixin;
