<template>
  <div class="fill-height" :class="{'hide-row-drag': !sidebarOpen}">
    <div v-show="!isEditFormOpen" class="fill-height">
      <ResourcesTableWrapper
        v-if="showTable"
        ref="grid"
        :isActive="showTable"
      />
    </div>
    <div v-show="isEditFormOpen" class="fill-height">
      <ResourceInfoEditor :isActive="isEditFormOpen" />
    </div>

    <OptSelectorDialog />
    <AreaPrioritySetterDialog />
    <ResourceInfoWrapper />
    <DeleteRecurringTaskConfirmDialog />
  </div>
</template>
<script>
import ResourcesTableWrapper from "./components/ResourcesTableWrapper/ResourcesTableWrapper.vue";
import ResourceInfoEditor from "@/components/ResourceInfoEditor/ResourceInfoEditor.vue";
import OptSelectorDialog from "@/components/OptSelectorDialog";
import AreaPrioritySetterDialog from "@/components/AreaPrioritySetterDialog";
import DeleteRecurringTaskConfirmDialog from "@/components/DeleteRecurringTaskConfirmDialog.vue";
import { mapGetters, mapState } from "vuex";
// import { isRedoHotKeys, isUndoHotKeys } from "@/utils/hotKeys";
// import resourcesHelpersMixin from "@/mixins/resourcesHelpersMixin";
// import resourcesUndoRedoHelpersMixin from "@/mixins/resourcesUndoRedoHelpersMixin";
// import EventEmitter from "@/helpers/eventEmitter";
// import { REDO_EVENT, UNDO_EVENT } from "@/variables/events";
import userActionUndoRedoMixin from "@/mixins/userActionUndoRedoMixin";
import mentionsHelpers from "@/mixins/mentionsHelpers";
import { checkIfSelectedResourceCateHasCategoryOnly } from "@/helpers/resources";
import ResourceInfoWrapper from "@/components/ResourceInfoPopUp/ResourceInfoWrapper.vue";

import { TOGGLE_ORDERED_TASKS } from "@/variables/events";
import EventEmitter from "@/helpers/eventEmitter";
import { isEscKey } from "@/utils/hotKeys";
export default {
  mixins: [userActionUndoRedoMixin, mentionsHelpers],
  components: {
    ResourcesTableWrapper,
    ResourceInfoEditor,
    OptSelectorDialog,
    AreaPrioritySetterDialog,
    ResourceInfoWrapper,
    DeleteRecurringTaskConfirmDialog,
  },
  props: {
    showTable: {
      type: Boolean,
      required: true,
    },
    showDialogs: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      makeAllTasksVisible: true,
    };
  },
  provide() {
    const toggleData = {};

    Object.defineProperty(toggleData, "showAllTasks", {
      enumerable: true,
      get: () => this.makeAllTasksVisible,
    });

    return {
      toggleData,
    };
  },
  mounted() {
    this.setKeyListeners();
    this.setUserUndoRedoEventListeners();
    EventEmitter.on(TOGGLE_ORDERED_TASKS, this.handleToggle);
  },
  beforeDestroy() {
    this.removeKeyListeners();
    this.removeUserUndoRedoEventListeners();
    EventEmitter.off(TOGGLE_ORDERED_TASKS, this.handleToggle);
  },
  methods: {
    // handleUndoRedoKeys(e) {
    //   this.handleUndoHotKeys(e);
    //   this.handleRedoHotKeys(e);
    // },
    // async handleRedoHotKeys(e) {
    //   if (!isRedoHotKeys(e)) return;
    //   e.stopImmediatePropagation();
    //   await this.handleRedoAction();
    // },

    // async handleUndoHotKeys(e) {
    //   if (!isUndoHotKeys(e)) return;
    //   e.stopImmediatePropagation();
    //   await this.handleUndoAction();
    // },
    handleToggle(value) {
      this.makeAllTasksVisible =
        value !== undefined ? value : !this.makeAllTasksVisible;
    },

    handleEscKey(e) {
      if (!isEscKey(e) || this.isEditFormOpen) return;
      e.stopPropagation?.();
      e.preventDefault?.();

      this.handleResourceViewData(this.currViewData);
    },
    handleHotKeys(e) {
      this.handleEscKey(e);
      this.handleUndoRedoKeys(e);
    },
    setKeyListeners() {
      document.addEventListener("keydown", this.handleHotKeys);
    },
    removeKeyListeners() {
      document.removeEventListener("keydown", this.handleHotKeys);
    },
    // setEventListeners() {
    //   EventEmitter.on(REDO_EVENT, this.handleRedoAction);
    //   EventEmitter.on(UNDO_EVENT, this.handleUndoAction);
    // },
    // removeEventListeners() {
    //   EventEmitter.off(REDO_EVENT, this.handleRedoAction);
    //   EventEmitter.off(UNDO_EVENT, this.handleUndoAction);
    // },
  },
  computed: {
    ...mapState(["sidebarOpen"]),
    ...mapGetters("resourceInfo", {
      isEditFormOpen: "isOpen",
      editResourceData: "data",
    }),
    ...mapGetters("resourcesData", ["selectedResources", "selectedCategories"]),
    ...mapGetters(["currViewData"]),
  },
};
</script>
<style scoped>
.resources-table-wrapper {
  padding-top: 10px;
}
</style>
