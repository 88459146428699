<template>
    <v-app>
    <v-app-bar app color="primary" flat name="TaskBar">
      <p class="white--text text-h5 mb-0">Chaos To Intention</p>
    </v-app-bar>

    <v-main>
      <v-container>
        <v-card class="pa-10">
          <p><strong>Chaos To Intention Privacy Policy</strong></p>
          
          <p>Last modified January 31, 2024 with an effective date of January 31, 2024.</p>
          
          <p>This privacy policy ("<strong>Policy</strong>") informs you of our practices when handling your Information
            through the Services (both as defined below).&nbsp; In this Policy, "<strong>Chaos To Intention</strong>,"
            "<strong>we</strong>," "<strong>our</strong>" or "<strong>us</strong>" refers to Chaos To Intention LLC, a company
            registered in North Carolina with its registered address located at 176 Ridge Trail, Chapel Hill, NC 27516.&nbsp;
            When you are using Chaos To Intention Services in your personal capacity, we are the data controller under the
            applicable privacy laws, and this Policy applies.</p>
          
          <p>For the purpose of this Policy, "<strong>Information</strong>" means any information relating to an identified or
            identifiable individual.&nbsp; This includes information you provide or generate when you use our website app
            (&ldquo;<strong>App</strong>&rdquo;) on Chaos To Intention.com and any other dedicated Chaos To Intention websites
            or Services which link to this policy ("<strong>Website</strong>" and together with the "<strong>Apps</strong>"
            the "<strong>Services</strong>").&nbsp; When you use the Services, you accept and understand we collect, process,
            use and store your Information as described in this Policy.&nbsp; <strong>If you do not agree with this Policy,
              you must not use any of the Services.&nbsp; If you change your mind in the future, you must stop using the
              Services and you may exercise your rights in relation to your Information as set out in this Policy.</strong>
          </p>
          
          <ol>
            <li><strong> Information we collect</strong></li>
          </ol>
          
          <p>We will collect and use the following Information about you:</p>
          <ul>
            <li><strong>Information you provide to us</strong></li>
            <ul>
              <li><strong>Registration information</strong>: for example, when you create an account on the Website, you will
                be asked to provide your name, job title, email, and a password.&nbsp; For paid services, we also collect your
                billing address, transaction information, tax identification number, Stripe identification number, and invoice
                address ("<strong>Payment Information</strong>").</li>
              <li><strong>Information collected via voluntary activities:</strong>&nbsp;for example, when you communicate with
                us via email, or participate in surveys, we ask you to provide your email address and any Information you
                choose to provide to allow us to assist you.&nbsp; The Information we collect from you varies depending on the
                survey, but we typically ask you questions about your interests and habits, your opinion about different
                products and services and what you'd like to be offered in the future.&nbsp; We also collect your ratings,
                opinions, preferences, questions, pictures, and responses associated with that survey.</li>
            </ul>
          </ul>
          
          <ul>
            <li><strong>Information we automatically collect or is generated about you when you use the Services</strong></li>
            <ul>
              <li><strong>Identifiers</strong>, such as your IP address, device ID, and device information (such as model,
                brand and operating system).</li>
              <li><strong>Cookies:</strong>&nbsp;we use cookies and other similar technologies ("<strong>Cookies</strong>") to
                enhance your experience when using the Services.&nbsp; For more information about our Cookies policy, see
                below&nbsp;<strong>How We Use Cookies and Similar Technologies section.</strong></li>
              <li><strong>Information you generate when using the Services:</strong>&nbsp;You may provide Information as part
                of your use of the Services, including any Information you provide when sending messages through the Services.
              </li>
            </ul>
            <li><strong>Information regarding your use of the Services</strong>, such as app use information, interactions
              with our team, and transaction records.</li>
            <li><strong>Information received from third parties.</strong></li>
            <ul>
              <li><strong>Information we receive from third party platforms:</strong>&nbsp;when you register through a
                third-party account (such as Facebook or Google) or when you connect other apps to our Services (such as Slack
                and Google Calendar), we receive Information which may include your username, email address, and profile
                picture.</li>
              <li><strong>Information from platforms our Services relies on</strong>, such as for transaction information and
                payment verification.</li>
            </ul>
          </ul>
          <p><strong>Children</strong></p>
          
          <p>Our Services are not targeted at children, and we do not knowingly collect Information from children under the
            age of 13.&nbsp; If you learn that a child has provided us with Information in violation of this Policy, please
            contact us as indicated below.</p>
          
          <ol start="2">
            <li><strong> How we use your personal Information</strong></li>
          </ol>
          
          <p>We use your Information to:&nbsp;<strong>Provide you with the Services.&nbsp; </strong>We will use your
            Information to perform our contractual obligation towards you to allow you to create an account and use the
            Services.&nbsp; The Information we process when doing so includes your registration information, Information you
            provide to us when using the Services, identifiers, Information you generate when using the Services, and
            Information regarding your use of the Services such as transaction information.&nbsp; If you are a user of Chaos
            To Intention's paid plans, we will use your Payment Information for payment processing purposes as well as sales
            tax collection and reporting as required by law.</p>
          <ul>
            <li><strong>Improve and monitor the Services.&nbsp; </strong>It is in our legitimate interests to improve our
              Services for our customers.&nbsp; When doing so, we may collect Information we automatically collect or is
              generated about you when you use the Services, as well as non-personal Information about your device such as
              device manufacturer, model and operating system, and the amount of free space on your device.</li>
            <li><strong>Provide you with support and to respond to your requests or complaints.&nbsp; </strong>If you reach
              out to us for support, we will use your Information to respond to and resolve your queries and complaints and
              facilitate support (e.g.&nbsp; retrieval of a forgotten password).&nbsp; When doing so, we perform our
              contractual obligation towards you.&nbsp; The Information we process when doing so includes your registration
              information, your identifiers, and any other information about you collected via our customer support channels.
            </li>
            <li><strong>Conduct analytics.&nbsp; </strong>It is in our legitimate interests to analyze the use of, and any
              other interaction or interest in our Services.&nbsp; When doing so we will process Information we automatically
              collect or is generated about you when you use the Services to create anonymized and aggregated data regarding
              your App usage.</li>
            <li><strong>Send you newsletters about product news, tips and tricks, daily productivity reports that may be of
                interest to you.&nbsp; </strong>We will send you emails with daily reports, newsletters with product news, and
              tips and tricks to use our Services.&nbsp; When doing so, we process your registration information.&nbsp; Your
              consent can be withdrawn at any time by following the unsubscribe mechanism at the bottom of each communication,
              or by visiting&nbsp;<a href="https://chaostointention.com/unsubscribe">ChaosToIntention.com/unsubscribe</a>.
            </li>
            <li><strong>Prevent fraud, defend Chaos To Intention against legal claims or disputes, enforce our terms and to
                comply with our legal obligations.&nbsp; </strong>It is in our legitimate interest to protect our interests by
              (1) monitoring the use of the Services to detect fraud or any other user behavior which prejudices the integrity
              of our Services, (2) taking steps to remedy aforementioned fraud and behavior, (3) defending ourselves against
              legal claims or disputes, and (4) enforcing our terms and policies.&nbsp; When doing so, we will process the
              Information relevant in such a case, including Information you provide us, Information we automatically collect
              about you, and Information which is provided to us by third parties.</li>
            <li><strong>Conduct surveys and Interviews.&nbsp; </strong>From time to time, we may ask you to participate in
              surveys and Interviews we conduct which are in our legitimate interest because they help us understand our
              userbase and improve the Services.&nbsp; If you participate, we process your registration information and any
              other Information collected through the survey questions.</li>
          </ul>
          
          <ol start="3">
            <li><strong> How we use cookies and similar technologies</strong></li>
          </ol>
          
          <p>Cookies are small files of letters and numbers that we store on your browser or the hard drive of your
            computer.&nbsp; They contain information that is transferred to your computer's hard drive. Our Services uses
            Cookies to collect information about your browsing activities and to distinguish you from other users of our
            Services.&nbsp; This aids your experience when you use our Services and also allows us to improve the
            functionality of our Services.</p>
          
          <p>We use the following cookies:</p>
          
          <ul>
            <li><strong>Strictly necessary cookies:</strong>&nbsp;Some cookies are strictly necessary to make our Services
              available to you; for example, to perform your login functionality and for user authentication and
              security.&nbsp; We cannot provide you with the Services without this type of cookies.</li>
            <li><strong>Functional cookies</strong>: These are used to recognize you when you return to our Website.&nbsp;
              This enables us to personalize our content for you, greet you by name and remember your preferences (for
              example, your choice of language or region).</li>
            <li><strong>Analytical and advertising cookies</strong>.&nbsp; We also use cookies for analytics purposes and
              advertising in order to operate, maintain, and improve our Services.&nbsp; We use third party analytics
              providers, including Google Analytics, to help us understand how users engage with the Services.&nbsp; Google
              Analytics uses first-party cookies to track user interactions which helps show how users use our Services.&nbsp;
              This information is used to compile reports and to help us improve our Services.&nbsp; The reports disclose
              Website trends without identifying individual visitors.&nbsp; You can opt out of Google Analytics without
              affecting how you visit our Website by going to&nbsp;<a
                href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>&nbsp;or via
              Google's Ads settings.</li>
          </ul>
          
          <p>We use the following analytical and advertising cookies:</p>
          <table>
            <thead>
              <tr>
                <td width="125">
                  <p><strong>Cookie</strong></p>
                </td>
                <td width="344">
                  <p><strong>Purpose</strong></p>
                </td>
                <td width="141">
                  <p><strong>Retention</strong></p>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td width="125">
                  <p>Google Analytics</p>
                </td>
                <td width="344">
                  <p>Analyzing website traffic and user behavior</p>
                </td>
                <td width="141">
                  <p>90 days</p>
                </td>
              </tr>
              <tr>
                <td width="125">
                  <p>Stripe</p>
                </td>
                <td width="344">
                  <p>Handling payments and pricing/upgrade page</p>
                </td>
                <td width="141">
                  <p>Session based</p>
                </td>
              </tr>
              <tr>
                <td width="125">
                  <p>Zendesk</p>
                </td>
                <td width="344">
                  <p>Loading images and providing support on Help Center</p>
                </td>
                <td width="141">
                  <p>Session based</p>
                </td>
              </tr>
              <tr>
                <td width="125">
                  <p>YouTube</p>
                </td>
                <td width="344">
                  <p>Displaying videos on Help Center pages</p>
                </td>
                <td width="141">
                  <p>Varies</p>
                </td>
              </tr>
            </tbody>
          </table>
          
          <p>You can block cookies by setting your internet browser to block some or all cookies.&nbsp; However, if you use
            your browser settings to block all cookies (including essential cookies) you may not be able to use our Services.
          </p>
          <p>Except for essential cookies, all cookies will expire after a maximum of 2 years.</p>
          
          <ol start="4">
            <li><strong> Who we share your personal Information with</strong></li>
          </ol>
          
          <p>We share your Information with selected third parties, including:</p>
          <ul>
            <li><strong>Other users</strong>&nbsp;who will see your profile information and any other information you choose
              to share with them through the Services.</li>
            <li><strong>Vendors and service providers we rely on for the provision of the Services</strong>, for example:</li>
            <li><strong>Cloud service providers</strong>&nbsp;who we rely on for data storage, including Amazon Web Services
              who are based in the U.S.; OpenAI, and Google Cloud.</li>
            <li><strong>Customer support solution providers</strong>, who help us manage and respond to our customer questions
              and complaints.&nbsp; This includes Zendesk LLC, which is based in the U.S.&nbsp; and which hosts our customer
              support function; and</li>
            <li><strong>Analytics providers</strong>.&nbsp; We work with a number of analytics, segmentation and mobile
              measurement service providers who help us understand our userbase.&nbsp; This includes Google LLC, which is
              based in the U.S.&nbsp; You can learn about Google's practices by going to&nbsp;<a
                href="https://www.google.com/policies/privacy/partners/">https://www.google.com/policies/privacy/partners/</a>,
              and opt-out of them by downloading the Google Analytics opt-out browser add-on, available at&nbsp;<a
                href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>.</li>
            <li><strong>Third Parties Service Integrations</strong>.&nbsp; When you connect third party apps to the Apps, you
              authorize us to share designated Information and data created and/or uploaded by you to our server with these
              third party services of your choice on your behalf.</li>
            <li><strong>Communications platform providers</strong>, who help us manage and send newsletters to you in relation
              to the Services.&nbsp; This includes MailChimp which is based in the U.S.</li>
            <li><strong>Payment processors</strong>, such as Stripe .&nbsp; This payment processor is responsible for the
              processing of your Information, and may use your Information for their own purposes in accordance with their
              privacy policies.&nbsp; More information is available at&nbsp;<a
                href="https://stripe.com/gb/privacy">https://stripe.com/gb/privacy</a>&nbsp;for Stripe.</li>
            <li><strong>Law enforcement agencies, public authorities or other judicial bodies and organizations.&nbsp;
              </strong>We disclose Information if we are legally required to do so, or if we have a good faith belief that
              such use is reasonably necessary to comply with a legal obligation, process or request; enforce our terms of
              service and other agreements, policies, and standards, including investigation of any potential violation
              thereof; detect, prevent or otherwise address security, fraud or technical issues; or protect the rights,
              property or safety of us, our users, a third party or the public as required or permitted by law (including
              exchanging information with other companies and organizations for the purposes of fraud protection).</li>
            <li><strong>Change of corporate ownership.&nbsp; </strong>If we are involved in a merger, acquisition, bankruptcy,
              reorganization, partnership, asset sale or other transaction, we may disclose your Information as part of that
              transaction.</li>
          </ul>
          
          <p>Although we have in place security measures to maintain the privacy and integrity of your Information, the
            transmission of Information via the internet is not completely secure.&nbsp; We may also take extra steps to
            protect your Information and minimize the Information we process.&nbsp; For example, when we store your
            Information, we use AES 256 encryption, and when we send or receive your Information, it is encrypted with TLS 1.1
            or above.&nbsp; Additionally, we are not responsible for how third-party integration services may collect, use or
            share the Information you send from the Apps.&nbsp; Please review the privacy policy of such third-party
            integration partners before connecting those services to the Apps.</p>
          
          <ol start="5">
            <li><strong> Where we store your Information</strong></li>
          </ol>
          
          <p>Your Information will be processed by our employees and service providers in the U.S.&nbsp; If you choose to use
            our Services from the European Economic Area ("EEA"), the United Kingdom ("UK") or other regions of the world with
            laws governing data collection and use that may differ from U.S.&nbsp; law, then please note that you are
            transferring your Information outside of those regions to the U.S. for storage and processing.&nbsp; We may
            transfer Information from the EEA or the UK to the U.S.&nbsp; and other third countries based on European
            Commission-approved or UK Government-approved Standard Contractual Clauses, or otherwise in accordance with
            applicable data protection laws.&nbsp; Also, we may transfer your data from the U.S.&nbsp; to other countries or
            regions in connection with storage and processing of data, fulfilling your requests, and operating our
            Services.&nbsp; You can contact us as detailed below if you want more information about our data transfer
            practices.</p>
          
          <ol start="6">
            <li><strong> How long we store your Information</strong></li>
          </ol>
          
          <p>Your Information is kept for as long as necessary to achieve the purposes set out above.&nbsp; When we process
            Information for our own purposes, we determine the retention period taking into account various criteria, such as
            the type of Services provided to you, the nature and length of our relationship with you, possible re-enrollment
            with our Services, the impact on the Services we provide to you if we delete some Information from or about you,
            and mandatory retention periods provided by law and the statute of limitations.&nbsp; Generally, we also delete
            your Information following a valid erasure request (see below&nbsp;Your Rights: Erasure&nbsp;section).&nbsp; Some
            Information we collect will be stored for longer where we have an overriding legitimate interest to retain such
            Information (for example, Information on suspicious behavior of certain users of our Services and transaction
            records).</p>
          
          <p>When deleting Information, we will take measures to make the Information irrecoverable or irreproducible, and
            electronic files which contain Information will be deleted permanently.</p>
          
          <ol start="7">
            <li><strong> Your rights</strong></li>
          </ol>
          
          <p>If you are based in the EEA or the UK, you have certain rights in relation to your Information.&nbsp; You will
            find more information below on when which rights can apply.&nbsp; To exercise your rights, please contact us
            at&nbsp;<a href="https://chaostointention.com/contact">https://ChaosToIntention.com/contact</a>.&nbsp; Before
            fulfilling your request, we may ask you to provide reasonable Information to verify your identity.&nbsp; Please
            note that there are exceptions and limitations to each of these rights, and that while any changes you make will
            be reflected in active user databases instantly or within a reasonable period of time, we may retain Information
            for backups, archiving, prevention of fraud and abuse, analytics, satisfaction of legal obligations, or where we
            otherwise reasonably believe that we have a legitimate reason to do so.</p>
          <ul>
            <li><strong>Access.&nbsp; </strong>You have the right to access Information, and to receive an explanation of how
              we use it and who we share it with.&nbsp; We provide full access to your Information via our API here:&nbsp; <a
                href="https://developer.ChaosToIntention.com/">https://developer.ChaosToIntention.com/</a></li>
          </ul>
          <p>Please note that payment information and integrations are not available via our API.&nbsp; In the case you want
            to obtain this information, please contact customer service at&nbsp;<a
              href="https://chaostointention.com/contact">https://ChaosToIntention.com/contact</a>.&nbsp; The right to access
            is not absolute.&nbsp; For example, we cannot reveal trade secrets, or give you Information about other
            individuals.</p>
          <ul>
            <li><strong>Erasure.&nbsp; </strong>You have the right to delete your account and erase your Information and upon
              deleting your account, all your Information will be removed from our production systems.&nbsp; Usually, only an
              encrypted copy of your Information will remain on our backup archives for 90 days, although we reserve the right
              to retain some of your Information where there are valid grounds for us to do so under data protection
              laws.&nbsp; For example, for the defense of legal claims, respect freedom of expression, or where we have an
              overriding legitimate interest to do so.</li>
          </ul>
          <p>Note that where the Information is held by a third-party data controller, such as a payment processor, we will
            use reasonable steps to inform them of your request, but we recommend you contact them directly in accordance with
            their own privacy policies to ensure your personal data is erased.</p>
          <ul>
            <li><strong>Objection.&nbsp; </strong>You may have the right to object to our processing of your
              Information.&nbsp; This is the case where we process such Information on the basis of our legitimate interests,
              or where the Information is used for direct marketing purposes.&nbsp; You may exercise this right as follows:
            </li>
            <li>To stop receiving marketing newsletters: You may withdraw your consent through the unsubscribe mechanism at
              the bottom of each communication.</li>
            <li>To stop our cookies being placed for either advertising or analytics purposes: please change your device or
              browser settings.</li>
            <li>To object to all other processing based on our legitimate interests, please contact us at <a
                href="https://ChaosToIntention.com/contact">https://ChaosToIntention.com/contact</a>.&nbsp; Please note that
              we may have an overriding legitimate interest to keep processing your Information, but we will let you know
              where this is the case.</li>
          </ul>
          
          <p><strong>Other rights</strong></p>
          
          <p>You also have the following rights:</p>
          <ul>
            <li><strong>Portability.&nbsp; </strong>You have the right to receive a copy of Information we process on the
              basis of consent or contract in a structured, commonly used and machine-readable format, or to request that such
              Information is transferred to a third party.</li>
            <li><strong>Correction</strong>.&nbsp; You have the right to correct any Information held about you that is
              inaccurate.</li>
            <li><strong>Restriction</strong>.&nbsp; You have a right in certain circumstances to stop us processing
              Information other than for storage purposes.</li>
            <li>You have the right to lodge a complaint with a supervisory authority in your country of residence, place of
              work or where an incident took place.</li>
          </ul>
          
          <ol start="8">
            <li><strong> Contact &amp; complaints</strong></li>
          </ol>
          
          <p>We welcome questions, comments, and requests regarding this Policy.&nbsp; For additional details and frequently
            asked questions about our policies, please read our <a
              href="https://chaostointention.com/help/articles/">Security, Privacy and GDPR FAQs</a>.</p>
          
          <p><strong>If you wish to make a complaint about how we process your Information</strong>, please contact us at <a
              href="https://chaostointention.com/contact">https://ChaosToIntention.com/contact</a>&nbsp;and we will endeavor
            to deal with your complaint as soon as possible.&nbsp; You can also send an email to us at&nbsp;<a
              href="mailto:privacy@chaostointention.com">privacy@ChaosToIntention.com</a>.&nbsp; Alternatively, if you are
            based in the EEA or the UK, you can send an email to our EU representative, DataRep at&nbsp;<a
              href="mailto:chaostointention@datarep.com">ChaosToIntention@datarep.com</a>&nbsp;or by filling out this&nbsp;<a
              href="https://www.datarep.com/data-request/">form</a>.</p>
          
          <ol start="9">
            <li><strong> Changes</strong></li>
          </ol>
          
          <p>If we make any material changes to this Policy, we will post the updated Policy here and notify our users through
            the Services and/or newsletters.&nbsp; Please check this page frequently to see any updates or changes to this
            Policy.</p>
        </v-card>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
export default {
  name: "PrivacyPolicy"
}
</script>